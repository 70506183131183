import { generatePath } from "react-router-dom";
import { axiosInstance } from "../common";

const ENDPOINT = `/item/:itemId`;

export const getProjectItemDetails = (token, itemId) => axiosInstance.get(generatePath(ENDPOINT, {
    itemId
}), {
    params: {
        token
    }
}).then(({ data }) => data)