import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useParams } from 'react-router-dom';
import { getVisuals } from '../../api/quotation';
import { FilePreview } from '../../components/ui/file-preview.jsx';
import { generateRouteUrl } from '../../library/constants/routes.js';
import styles from './quotation-visual.module.scss';

const QuotationVisual = () => {
    const { t } = useTranslation()
    const { quotationToken } = useParams()
    // const { quotation } = useRouteLoaderData('QuotationRoot') ?? {}
    const { visual } = useLoaderData()

    return (
        <div className="wrapper quotation-wrapper">
            <div className="main">
                <h2 className="main__title">{t('Visual')}</h2>
                <div className="box box--white">
                    <div className="actions align--right">
                        <Link to={generateRouteUrl('QuotationDetails', { quotationToken })} className="button"><img src="/icons/arrow.svg" alt="back" /> {t("Back")}</Link>
                    </div>
                    <div className="box">
                        <div className={styles.visuals}>
                            {visual.map(file => (
                                <FilePreview key={file.url} name={file.name} path={file.url} comment={file.comment} quotationToken={quotationToken} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

QuotationVisual.Loader = async ({ params }) => {
    const { quotationToken } = params
    const visual = await getVisuals(quotationToken)
    return { visual }
}

export default QuotationVisual
