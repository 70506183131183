
import React from 'react';
import {useTranslation} from 'react-i18next'
import { Link, useParams } from 'react-router-dom';
import { generateRouteUrl } from '../../library/constants/routes.js';
import styles from './help-and-support.module.scss';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const HelpAndSupport = () => {
    const {t} = useTranslation()
    const { quotationToken, itemId } = useParams()
    // const { projectItem, client_document } = useLoaderData()
    return (
        <div className="wrapper HelpAndSupport-wrapper">
            <div className="main">
                <h2 className="main--title">
                    <img src="/icons/stats.svg" alt="" />
                    {t("Help and Support")}
                </h2>
                <div className={`box--white ${styles.wrapper}`}>
                    <div className={styles.header}>
                        <h3>{t("Name of the template")}</h3>

                        <Link to={generateRouteUrl('Dashboard', {
                            quotationToken,
                        })} className={styles.button}> <img src="/icons/arrow.svg" alt="back" />{t("Back")}</Link>


                    </div>
                    <div className={styles.outerHelpBox}>
                        <div className={styles.helpBox}> <QuestionCircleOutlined style={{ fontSize: '32px' }} /><h3>{t("Do you need help ?")}</h3></div>
                        <div className={styles.helpBox}> <ExclamationCircleOutlined style={{ fontSize: '32px' }} />
                            <Link to={generateRouteUrl('ShareYourFeedback', {
                                quotationToken,
                                itemId: itemId,
                            })}><h3>{t("Do you have a complain ?")}</h3></Link></div>

                    </div>
                </div>


            </div>
        </div>
    )
}


export default HelpAndSupport