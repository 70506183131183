import { getEnvVariable } from "../../App.jsx";
import { getStoredToken } from "../constants/auth.js";

export const getSecuredFileUrl = (filePath) => {
    //Get token from Local Storage
    let url = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/${filePath}`)
    url.searchParams.set('token', getStoredToken())
    console.log(url);
    return url.toString()
}

export const getFilePath = (url, token) => {
    let BaseUrl = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/file/${token}`)
    return (`${BaseUrl}/${url}`)
}
export const viewFilePath = (url, token) => {
    let BaseUrl = new URL(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/file/read/${token}`)
    return (`${BaseUrl}/${url}`)
}

export const getPreviewUrl = (file, token = null) => {
    const extention = file.split('.').pop()
    switch (extention) {
        case "svg":
            return viewFilePath(file, token)
        case "png":
            return viewFilePath(file, token)
        case "jpg":
            return viewFilePath(file, token)
        case "jpeg":
            return viewFilePath(file, token)
        case "ai":
            return "/icons/filetypes/ai.svg"
        case "pdf":
            return "/icons/filetypes/pdf.svg"
        default:
            return "/icons/filetypes/file.svg"
    }
}

export const getCmsFileUrl = (file) => `https://leoprinting.nl/files/photos/${file}`

export const getPublicFileUrl = (file) => `${getEnvVariable('REACT_APP_SERVER_BASE_URL')}/data/${getEnvVariable('REACT_APP_VERSION_V1')}/${file}`