export const DateFormatted = (date) => {
    const dateObj = new Date(date)
    if (isNaN(dateObj)) {
        return "NA"
    }
    return new Intl.DateTimeFormat('de-DE').format(dateObj)
}

export const subtractDaysFromDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}

export const AddOrSubractDays = (startingDate, number, add) => {
    if (add) {
        return new Date(new Date().setDate(startingDate.getDate() + number));
    } else {
        return new Date(new Date().setDate(startingDate.getDate() - number));
    }
}

export const DifferenceBetweenDates = (date1, date2) => {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    var diffDays = parseInt((d2 - d1) / (1000 * 60 * 60 * 24), 10);

    return diffDays
}