import React from 'react';
import { Link, Outlet, RouterProvider, createBrowserRouter, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Error404 } from './components/errors/index.js';
import Page from './components/layout/page.jsx';
import i18n from './i18n';
import { getUser, isAuthenticated, redirectToLogin, routesList } from "./library/constants";
import { formDataToObject } from './library/utilities/formdata.js';
import Login from './routes/login/login.jsx';
const formatRoute = (route) => {
  const { id = undefined, component: Component, title, url: path, isProtected = true, errorElement = null } = route
  const obj = {
    element: Component ? <Component title={title} /> : <div><Outlet /></div>,
  }
  if (id) {
    obj.id = id
  }
  if (path) {
    obj.path = path
  }
  else {
    obj.index = true
  }
  obj.handle = {
    isProtected: !!isProtected
  }
  if (Component?.ErrorElement) {
    obj.errorElement = <Component.ErrorElement />
  }
  if (route.children) {
    obj.children = route.children.map(formatRoute)
  }

  if (route?.isProtected) {
    obj.loader = (params) => {
      if (!params?.params?.quotationToken || !isAuthenticated(params.params.quotationToken)) {
        return redirectToLogin(params.params.quotationToken, new URL(params.request.url).pathname)
      }
      if (Component?.Loader) {
        return Component.Loader(params)
      }
      return {}
    }
  }
  else if (Component?.Loader) {
    obj.loader = Component.Loader
  }

  if (Component?.Action && Component?.Actions) {
    throw new Error('Route cannot have both `Action` and `Actions`', route)
  }

  if (Component?.Actions) {
    obj.action = async ({ params, request }) => {
      //const { action, ...data } = Object.fromEntries(await request.formData())
      let parsedRequestBody = null
      if (request.headers.get('content-type') === 'application/json') {
        parsedRequestBody = await request.json()
      }
      else {
        parsedRequestBody = formDataToObject(await request.formData())
      }
      const { action, ...data } = parsedRequestBody
      const { method } = request
      if (!Component.Actions[action]) {
        throw new Error(`Unknow action: ${action} on route: ${Component.name}`, route)
      }
      return await Component.Actions[action]({ params, method, data })
    }
  }
  else if (Component?.Action) {
    obj.action = Component.Action
  }
  return obj
}

// const loadTranslation = async (language) => {
//   const translations = await import(`../public/locales/${language}/common.json`);
//   i18n.addResourceBundle(language, 'translation', translations, true, true);
//   return translations;
// }

// Example of changing the language and loading translations lazily
export const changeLanguage = async (newLanguage) => {
  // await loadTranslation(newLanguage);
  // i18n.changeLanguage(newLanguage);
  // await loadTranslation(newLanguage);
  i18n.changeLanguage(newLanguage);
}


function App() {
  return (
    <React.Fragment>
      <RouterProvider router={createBrowserRouter([
        {
          element: <Outlet />,
          id: 'Root',
          errorElement: <ErrorBoundary />,
          loader: async ({ params }) => {
            const { quotationToken } = params

            if (!quotationToken) {
              throw new Response("", { status: 404 });
            }
            const user = getUser(quotationToken)

            return { user }
          },
          children: [
            {
              errorElement: <ErrorBoundary />,
              children: routesList.map(formatRoute),
            },
          ]
        },
        {
          path: '/authenticate/:quotationToken',
          element: <Login />,
          errorElement: <ErrorBoundary />,
          loader: Login.Loader,
          action: async ({ params, request }) => {
            //const { action, ...data } = Object.fromEntries(await request.formData())
            const { action, ...data } = formDataToObject(await request.formData())
            const { method, url } = request
            if (!Login.Actions[action]) {
              throw new Error(`Unknow action: ${action} on route: Login`)
            }
            return await Login.Actions[action]({ params, method, data, url })
          },
        },
        // {
        //   //path: '/',
        //   element: <AuthenticatedPage />,
        //   errorElement: <ErrorBoundary />,
        //   children: routesList.map(formatRoute),
        //   action: ({ params, data }) => {
        //     return {}
        //   },
        //   loader: ({ params }) => {
        //     const { quotationToken } = params
        //     let userLanguage = navigator.language.split('-')?.[0] ?? 'en'
        //     //Check if user is logged
        //     if (localStorage.getItem('USER')) {
        //       const { token, contact_language } = JSON.parse(localStorage.getItem('USER'))
        //       if (token === quotationToken && contact_language) {
        //         userLanguage = contact_language.split('_')?.[1] ?? contact_language
        //       }
        //     }
        //     changeLanguage(userLanguage)
        //     //changeLanguage(userLanguage)
        //     return { userLanguage }
        //   }
        // }
      ])} />
    </React.Fragment>
  );
}

function ErrorBoundary() {
  let error = useRouteError();

  if (isRouteErrorResponse(error) || error.constructor === Response) {
    if (error.status === 404) {
      return <Error404 />;
    }
  }
  console.error("Inside global error boundary", error);

  //throw error
  //Uncaught ReferenceError: path is not defined
  return (
    <Page projectId={"234234"} className="center">
      <img src='/images/404-lion.svg' style={{ maxWidth: '40%', maxHeight: '30vh' }} alt="404" />
      <h2>Some unexpected error has occurred</h2>
      <h3>Leoprinting has been notified about the error.</h3>
      <p>Error Details : {error.message}</p>
      <div className="actions">
        <Link className="button" to={-1}><img src="/icons/arrow.svg" alt="back" /> Back</Link>
        <Link className="button" to={0}>Reload</Link>
      </div>
    </Page>
  );
}

export const getEnvVariable = (key) => {
  if (!process.env[key]) {
    console.warn(`Please configure ${key} in environment`)
    return ''
  }
  return process.env[key]
}

export default App;