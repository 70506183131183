import { customAlphabet } from 'nanoid/non-secure';
import { useEffect, useMemo, useState } from 'react';
const nanoid = customAlphabet('1234567890abcdef', 6)

const LIMIT_ADDRESSES = 5
const useMultipleAddresses = (initialAddresses, totalQuantity) => {
    const [addresses, setAddresses] = useState(initialAddresses.map(i => ({ ...i, id: nanoid() })))
    const getCurrentTotal = (items) => items.reduce((t, { quantity }) => t + parseInt(quantity), 0)

    const isValid = useMemo(() => {
        const quantityUsed = addresses.reduce((p, q) => (p + q.quantity), 0)
        const allAddressesSelected = !addresses.some(({ address_id }) => !address_id)
        return (quantityUsed === totalQuantity && allAddressesSelected)
    }, [addresses, totalQuantity])

    useEffect(() => {
        //Add quantity if not quantity at all
        if (!addresses || !Array.isArray(addresses) || addresses.length === 0) {
            setAddresses([{
                id: nanoid(),
                address_id: 0,
                notes: "",
                quantity: totalQuantity
            }])
        }
    }, [])

    const updateAddress = (id, address) => {
        if (isNaN(address.quantity)) {
            return
        }

        const currentIndex = addresses.findIndex(a => a.id === id)
        const _addresses = structuredClone(addresses)
        const extraIndex = _addresses.findIndex(a => a.id != id && !a.address_id && !a.notes)

        _addresses[currentIndex] = { ...address, id }

        //If total exceeds , check if we can adjust
        if (getCurrentTotal(_addresses) > totalQuantity) {
            // console.log({ extraIndex, extra: _addresses[extraIndex].quantity })
            if (extraIndex < 0 || totalQuantity - getCurrentTotal(_addresses) > _addresses[extraIndex].quantity) {
                //Can't adjust
                _addresses[currentIndex].quantity += (totalQuantity - getCurrentTotal(_addresses))
                setAddresses(_addresses)
                return
            }


            //Adjustable
            _addresses[extraIndex].quantity -= (getCurrentTotal(_addresses) - totalQuantity)
        }
        else if (getCurrentTotal(_addresses) < totalQuantity && _addresses.length < LIMIT_ADDRESSES) {
            //Create extra item if not already there/
            if (extraIndex < 0) {
                _addresses.push({
                    id: nanoid(),
                    address_id: 0,
                    notes: "",
                    quantity: totalQuantity - getCurrentTotal(_addresses)
                })
            }
            else {
                _addresses[extraIndex].quantity += (totalQuantity - getCurrentTotal(_addresses))
            }
        }

        setAddresses(_addresses.filter(a => a.quantity > 0))
    }

    return [addresses, updateAddress, isValid];
}

export default useMultipleAddresses