import { axiosInstance } from "../common";

const ENDPOINT = `/customer/address`;

export const getCustomerAddresses = (token) => axiosInstance.get(ENDPOINT, {
    params: {
        token
    }
}).then(({ data }) => data)

export const addCustomerAddress = (token, address) => axiosInstance.post(ENDPOINT, address, {
    params: {
        token
    }
}).then(({ status }) => status)