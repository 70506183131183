import { generatePath } from "react-router-dom";
import { axiosInstance } from "../common";

const ENDPOINT = `/item/:itemId/:action`;

export const requestSampleArtwork = (token, itemId) => axiosInstance.post(generatePath(ENDPOINT, {
    itemId,
    action: 'sample'
}), {}, {
    params: {
        token
    }
}).then(({ message }) => message)

export const getArtworks = (token, itemId) => axiosInstance.get(generatePath(ENDPOINT, {
    itemId,
    action: 'artwork'
}), {
    params: {
        token
    }
}).then(({ data }) => data)


export const approveArtwork = (token, itemId) => axiosInstance.post(generatePath(ENDPOINT, {
    itemId,
    action: 'artwork/approved'
}),
    {}, {
    params: {
        token
    }
}).then(({ message }) => message)

export const rejectAndRequestChange = (token, itemId, payload) => axiosInstance.post(generatePath(ENDPOINT, {
    itemId,
    action: 'artwork/rejected'
}), payload, {
    params: {
        token
    }
}).then(({ message }) => message)

export const getDocuments = (token, itemId) => axiosInstance.get(generatePath(ENDPOINT, {
    itemId,
    action: 'document'
}), {
    params: {
        token
    }
}).then(({ data }) => ({
    ...data,
    id: itemId
}))
export const sendArtworks = (token, itemId, fileIds) => axiosInstance.post(generatePath(ENDPOINT, {
    itemId,
    action: 'artwork'
}), {
    image_ids: fileIds
}, {
    params: {
        token
    }
})