import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, redirect, useActionData, useFetcher, useLoaderData, useParams } from 'react-router-dom';
import { uploadFileFromObject } from '../../api/common';
import { getDocuments, getProjectItemDetails, sendArtworks } from '../../api/project';
import { Image, Message, MessageTypes, Modal, MultiFileUploadwithComment } from '../../components/ui';
import { FilePreview } from '../../components/ui/file-preview.jsx';
import { ActionError } from '../../library/classes/exceptions';
import { getFilePath, getPreviewUrl, viewFilePath } from '../../library/constants';
import { generateRouteUrl } from '../../library/constants/routes.js';
import styles from './client-artwork.module.scss';

const ArtworkThumbnail = ({ name, url, comment, token }) => {
    const { t } = useTranslation()

    return (<div className={styles.file} key={name}>
        <Image src={getPreviewUrl(url)} width="100" className={styles.historyImg} />
        <div style={{ width: '200px', overflow: 'hidden' }}>
            <p className="label">{name}</p>
        </div>
        <div style={{ 'width': '25%' }}>
            <p>{comment}</p>
        </div>
        <div style={{ 'width': '20%' }}>
            <div className={`actions--compact ${styles.artworkActions}`}>
                <a className="button" href={viewFilePath(url, token)} target='_blank'><img src="/icons/lens-plus.svg" />{t("View")}</a>
                <a href={getFilePath(url, token)} className="button" download target="_blank"><img src="/icons/download.svg" />{t("Download")}</a>
            </div>
        </div>
    </div>)
}
const ArtworksPreview = ({ documents, token }) => (
    <div className={styles.artworkList}>
        {documents.map(({ name, url, comment }) => <ArtworkThumbnail key={url} url={url} name={name} comment={comment} token={token} />)}
    </div >
)
const ClientArtwork = () => {
    const { quotationToken, itemId } = useParams()
    const { projectItem, clientArtwork } = useLoaderData()
    const [isMessageVisible, setMessageVisisble] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const fileUploaderRef = useRef([])
    const actionData = useActionData()
    const { t } = useTranslation()
    const fetcher = useFetcher()

    useEffect(() => {
        setIsUploading(fetcher.state !== 'idle')
    }, [fetcher.state])
    const handleFilesUpload = async () => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }
        setIsUploading(true)
        const promises = fileUploaderRef.current.map(({ file }) => uploadFileFromObject(quotationToken, file))
        try {
            const fileIds = await Promise.all(promises)
            const imageData = fileIds.map((id, index) => ({
                id,
                comment: fileUploaderRef.current[index].comment
            }))

            fetcher.submit({
                imageData,
                id: itemId,
                action: 'uploadArtwork'
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        catch (error) {
            setIsUploading(false)
            throw new Response('Unable to upload files', { status: 200 })
        }
        setIsUploading(false)
    }
    return (
        <div className="wrapper clientartwork-wrapper">
            <div className="main">
                <h2 className="main--title">
                    <img src="/icons/add-location.svg" alt="" />
                    {t("Upload History")}
                </h2>
                <div className={`box--white ${styles.wrapper}`}>
                    <div className={styles.header}>
                        <h2>{projectItem.product.name}</h2>
                        <Link to={generateRouteUrl('Dashboard', { quotationToken })} className="button"> <img src="/icons/arrow.svg" alt="back" /> {t("Back")}</Link>
                    </div>
                    <div>
                        {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                        {actionData === true && <Message type={MessageTypes.SUCCESS} message="File Uploaded Successfully" timeOut={3} />}
                        <MultiFileUploadwithComment ref={fileUploaderRef} />
                        <i>{t("Supported formats : PDF ,ESP,PSD,AI,TIFF,SVG")}</i>
                    </div>
                    <div className="actions align--right">
                        <button className="button--primary" type="submit" onClick={() => {
                            if (fileUploaderRef.current.length === 0) {
                                alert('Please select atleast one file')
                                return
                            }
                            setMessageVisisble(true)
                        }}>{t('Upload')}</button>
                    </div>
                    {clientArtwork.length > 0 && (
                        <div className={styles.uploadedArtworks}>
                            <h2>{t("Upload History")}</h2>
                            <div className={styles.artworksHistory}>
                                <ArtworksPreview key={clientArtwork.id} documents={clientArtwork} token={quotationToken} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Modal isVisible={isMessageVisible} onClose={() => {
                setMessageVisisble(false)
            }}>
                <div className={isUploading ? 'loading' : ''}>
                    <p>{t("Please confirm if you intended to send these artworks to Leoprinting. This action cannot be reversed")}</p>
                    {fileUploaderRef.current.map(({ file, comment }) => <FilePreview key={file.preview} name={file.name} fileObj={file} comment={comment} quotationToken={quotationToken} />)}
                    <br />
                    <div className="actions">
                        <button className="button--danger button--large" onClick={() => setMessageVisisble(false)} disabled={isUploading}>{t("Cancel")}</button>
                        <button className="button--primary button--large" onClick={() => handleFilesUpload()} disabled={isUploading}>{t("Yes")}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

ClientArtwork.Loader = async ({ params }) => {
    const { quotationToken, itemId } = params
    const projectItem = await getProjectItemDetails(quotationToken, itemId)
    const { client_artwork: clientArtwork = [] } = await getDocuments(quotationToken, itemId)
    return { projectItem, clientArtwork }
}
ClientArtwork.Actions = {
    uploadArtwork: async ({ params, data }) => {
        const { quotationToken, itemId } = params
        const { imageData } = data

        try {
            await sendArtworks(quotationToken, itemId, imageData)
            return redirect(generateRouteUrl('Dashboard', { quotationToken }))
        }
        catch (error) {
            return { error: error }
        }
    },

}
export default ClientArtwork