export function formDataToObject(formData) {
    const data = {};

    for (let [key, value] of formData.entries()) {
        let keys = key.split(/\[(.*?)\]/).filter((k) => k); // split on brackets
        let obj = data;

        keys.forEach((k, index) => {
            if (!isNaN(parseInt(k))) { // if key is a number, initialize array
                obj = obj || [];
                obj = obj[parseInt(k)] = obj[parseInt(k)] || {};
            } else if (index === keys.length - 1) {
                obj[k] = value;
            } else {
                obj[k] = obj[k] || {};
                obj = obj[k];
            }
        });
    }

    return data;
}