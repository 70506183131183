import { Image } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getPreviewUrl } from '../../library/utilities/urls.js';
import styles from './file-preview.module.scss';
import ViewAndDownload, { ViewBlob } from './view_download_buttons.jsx';

const isPreviewable = (path) => {
    const extention = path.split('.').pop()
    return ['jpg', 'jpeg', 'png', 'svg', 'pdf'].includes(extention)
}
const getBlobPreviewUrl = (file) => {
    if (/image\/[a-z+.-]+/.test(file.type)) {
        return file.preview
    }
    switch (file.type) {
        case "application/pdf":
            return "/icons/filetypes/pdf.svg"
        default:
            return "/icons/filetypes/file.svg"
    }
}

const FilePreview = ({ name, path, fileObj, comment = '', quotationToken }) => {
    const { t } = useTranslation()
    if (!path && !fileObj) {
        return <>ERROR</>
    }
    const getPreview = () => {
        if (fileObj) {
            return <img alt="" src={getBlobPreviewUrl(fileObj)} width={'100px'} height={'100px'} />
        }
        else if (isPreviewable(path)) {
            return <Image src={getPreviewUrl(path, quotationToken)} width={'100px'} height={'100px'} />
        }
        <img alt="" src={getPreviewUrl(path, quotationToken)} width={'100px'} height={'100px'} />
    }

    return (
        <div className={styles.wrapper}>
            {getPreview()}
            <div className={styles.overflow}>
                <div><b>{t("Name")}</b> : {name}</div>
                <br />
                <div><b>{t("Comment")}</b> : {comment}</div>
            </div>
            {fileObj ? <ViewBlob obj={fileObj}/> : <ViewAndDownload url={path} quotationToken={quotationToken} />}
        </div>
    )
}


FilePreview.propTypes = {
    name: PropTypes.string.isRequired,
    fileObj: PropTypes.object,
    path: PropTypes.string,
    quotationToken: PropTypes.string.isRequired,
    comment: PropTypes.string,

}

export { FilePreview };

