import React, { useState } from "react";
import { DropdownSelect } from "../../components/inputs/index";
// import { DownSquareOutlined } from '@ant-design/icons';



const reasonForComlaints = [

    'Printing quality and further processing'
    ,
    'Wrong delivery'
    ,
    'Wring quantity supplied'
    ,
    'Delayed delivery'
    ,
    'Transport Damage'
    ,
];

const ReasonForComplaint = () => {
    const [reason , setReason]= useState()
    return (<DropdownSelect options={reasonForComlaints} value={reason} onChange={(e)=>{setReason(e)}}/>
    )
}

export default ReasonForComplaint