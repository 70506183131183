import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher } from 'react-router-dom';
import { FilePreview } from './file-preview';
import styles from './make-artwork.module.scss';
import Modal from './modal';

const MakeArtworkRecievedFiles = ({ documents, quotationToken, itemId }) => {
    const [rejectComment, setRejectComment] = useState('')
    // const [fileModal,showFileModal]= useState(false)
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)
    const [isMessageVisible, setMessageVisisble] = useState(false)
    const [isRequestChangeModalVisisble, setIsRequestChangeModalVisisble] = useState(false)
    const { t } = useTranslation();
    const fetcher = useFetcher()

    // const requestChange = async () => {
    //     try {
    //         const msg = await rejectArtworkMadeByLP(quotationToken, itemId, { comment: rejectComment })
    //         alert(msg);
    //         setMessageVisisble(true)
    //         setIsRequestChangeModalVisisble(false)

    //     }
    //     catch (e) {
    //         alert(e)
    //     }
    // }
    // const approveDesign = () => {
    //     try {
    //         const response = approveArtworkMadeByLP(quotationToken, itemId)
    //         setIsConfirmationModalVisible(true)
    //     }
    //     catch (e) {
    //         alert(e)

    //     }

    // }

    const requestChange = async () => {
        const payload = { itemId, rejectComment }
        try {
            fetcher.submit({
                ...payload,
                action: 'rejectMakeArtwork'
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        catch (e) {
            return alert(e.message)
        }
    }
    const approveDesign = () => {
        try {
            fetcher.submit({
                itemId,
                action: 'approveMakeArtwork'
            }, {
                method: 'post',
                encType: 'application/json'
            })
        }
        catch (e) {
            return alert(e.message)
        }


    }
    return (
        <div className={`box--blue-light ${styles.task}`}>
            <div className={styles.taskContent}>
                <span>{t("Leoprinting hasve created an artwork for you please review it.")}</span>
            </div>
            <br />
            <div className={styles.innerTaskBox}>
                {documents.map((artwork) => {
                    return (
                        <div key={artwork.url}>
                            <FilePreview name={artwork.name} comment={artwork.comment} path={artwork.url} quotationToken={quotationToken} />
                        </div>

                    )
                })}

                <div className={styles.proffText}>
                    <h3>{t("Check your Artwork for the following points:")}</h3>
                    <p>{t("1. Is your logo on the right position ?")}</p>
                    <p>{t("2. Is the spelling or punctuation correct")}</p>
                    <hr />
                    <div className={styles.taskAction}>
                        <button onClick={approveDesign} className={styles.button}>{t("Approve Design")}</button>
                    </div>
                    <br />
                    <i>{t("On approval, the artwork will be converted into final Artwork. You will then not able to request a change")}</i>
                    <br />
                    <br />
                    <div className={styles.taskAction}>
                        <button onClick={() => { setIsRequestChangeModalVisisble(true) }} className={styles.button}>{t("Reject Artowrk")}</button>
                    </div>
                    <hr />
                    <Modal onClose={() => { setMessageVisisble(false) }} isVisible={isMessageVisible}>
                        <p>{t("Artwork has been rejected")}</p>
                        <button className="button--danger button--large" onClick={() => setMessageVisisble(false)}>OK</button>

                    </Modal>
                    <Modal title={t('Design Approved')} onClose={() => { setIsRequestChangeModalVisisble(false) }} isVisible={isRequestChangeModalVisisble}>
                        <div>
                            <p>{t("Please tell us why are you rejecting the artwork")}</p>
                            <textarea name="changeComment" onChange={(e) => { setRejectComment(e.target.value) }} rows="4" cols="50" />
                        </div>
                        <div className="actions">
                            <button className="button--danger button--large" onClick={() => setIsRequestChangeModalVisisble(false)}>{t("Cancel")}</button>
                            <button className="button--primary button--large" onClick={requestChange}>{t("Yes")}</button>
                        </div>
                    </Modal>

                    <Modal title={t('Design Approved')} onClose={() => { setIsConfirmationModalVisible(false) }} isVisible={isConfirmationModalVisible}>
                        <div>
                            <p>{t("Your Artwork approval has been sent ot Leoprintings")}</p>

                        </div>
                        <div className="actions">
                            <button className="button--danger button--large" onClick={() => setIsConfirmationModalVisible(false)}>{t("OK")}</button>

                        </div>
                    </Modal>

                </div>

            </div>

        </div>
    )
}

MakeArtworkRecievedFiles.propTypes = {
    documents: PropTypes.array.isRequired,
    quotationToken: PropTypes.string,
    itemId: PropTypes.number

}

export default MakeArtworkRecievedFiles