import { useTranslation } from 'react-i18next';
import { getEnvVariable } from '../../App.jsx';
import { useHubspotChat } from '../../hooks';
import styles from './header.module.scss';

const Header = () => {
    const { hasLoaded, openHandler } = useHubspotChat(getEnvVariable('REACT_APP_HUBSPOT_APP_ID'));
    const { t } = useTranslation()
    return (
        <header className={`container ${styles.headerWrapper}`}>
            <div className={styles.logo}>
                <img src='/images/logo.svg' alt="Leoprinting" />
            </div>
            <div className={styles.actions} style={{ visibility: hasLoaded ? 'visible' : 'hidden' }}>
                <a className={`button--plain ${styles.chatButton}`} onClick={openHandler}>
                    <img src="/icons/chat.svg" />
                    {t('Chat with us')}
                </a>
                <a className={`button--primary ${styles.phoneButton}`} href="tel:4420123213">
                    <img src="/icons/phone.svg" />
                    4420123213
                </a>
            </div>
        </header>
    )
}

export default Header