import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { uploadFileFromCanvas, uploadFileFromObject } from '../../api/common/file-upload.js';
import { approveQuotation, getQuotationDetails } from '../../api/quotation/quotation.js';
import { DrawSignature, TextSignature, UploadSignature } from '../../components/signature';
import { Modal, Tabs } from '../../components/ui';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { LocalStorage } from '../../library/utilities/localstorage.js';

const QuotationSignature = () => {
    const { quotationToken } = useParams()
    const { selectedItems } = useLoaderData()
    const navigate = useNavigate()
    const signatureMode = useRef('draw')
    const drawSignCanvasRef = useRef(null)
    const textSignCanvasRef = useRef(null)
    const uploadSignRef = useRef(null)
    const [quotationId, setQuotationId] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { t } = useTranslation();

    const handleSignClick = async () => {
        let signatureId;
        setIsSubmitting(true)
        switch (signatureMode.current) {
            case "draw":
                //Check if signature filled
                if (drawSignCanvasRef.current.isEmpty()) {
                    alert(t('Signature is required'))
                    setIsSubmitting(false)
                    return
                }
                signatureId = await uploadFileFromCanvas(quotationToken, drawSignCanvasRef.current.getCanvas())
                break;

            case "type":
                //Check if signature filled
                if (textSignCanvasRef.current.text.length === 0) {
                    alert(t('Signature is required'))
                    setIsSubmitting(false)
                    return
                }
                signatureId = await uploadFileFromCanvas(quotationToken, textSignCanvasRef.current.getCanvas())
                break;

            case "upload":
                //Check if signature filled
                if (!uploadSignRef.current || !(uploadSignRef.current instanceof File)) {
                    alert(t('Signature is required'))
                    setIsSubmitting(false)
                    return
                }
                signatureId = await uploadFileFromObject(quotationToken, uploadSignRef.current)
                break;
        }

        if (!signatureId) {
            setIsSubmitting(false)
            throw new Response('Unable to upload signature', { status: 500 })
        }

        try {
            const { quotation_id = null } = await approveQuotation(quotationToken, signatureId, Object.values(selectedItems))
            setIsSubmitting(false)
            if (!quotation_id) {
                throw new Response(t('Unable to approve quotation. Please contact support'), { status: 500 })
            }
            //Quotation approved successfully
            setQuotationId(quotation_id)
        }
        catch (e) {
            setIsSubmitting(false)
            alert(e.message)
        }
    }

    return (
        <div className="wrapper quotation-wrapper">
            <div className="main">
                <div className={`box--white ${isSubmitting ? 'loading' : ''}`}>
                    <p>{t("Your signature is required to confirm the order.")}</p>
                    <Tabs onTabChange={(activeTab) => {
                        signatureMode.current = activeTab
                    }}>
                        <div name="draw" label={<><img src="/icons/signature.svg" /> {t("Draw Signature")}</>}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <p><strong>{t("Signature")}</strong></p>
                                <p>
                                    <a className='button--small button--danger' onClick={() => { drawSignCanvasRef.current.clear() }}>{t("Clear")}</a>
                                </p>
                            </div>
                            <DrawSignature ref={drawSignCanvasRef} />
                        </div>
                        <div name="type" label={<><img src="/icons/text.svg" />{t("Type Signature")}</>}>
                            <p>{t("Confirm your name and choose signature style")}</p>
                            <p>{t("Full Name Signature")}</p>
                            <TextSignature ref={textSignCanvasRef} />
                        </div>
                        <div name="upload" label={<><img src="/icons/upload.svg" />{t("Upload Signature")}</>}>
                            <UploadSignature ref={uploadSignRef} />
                        </div>
                    </Tabs>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p>{t("By clicking on signature confirm, I understand that I am signing this document.")}</p>
                        <div className="actions">
                            <button className="button--primary" onClick={handleSignClick} disabled={isSubmitting}>{t("Sign and Confirm")}</button>
                            <button className="button--danger" onClick={() => {
                                navigate(generateRouteUrl('QuotationDetails', { quotationToken }))
                            }} disabled={isSubmitting}>{t("Cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title={t('Confirmation letter')} isVisible={!!quotationId} width='500px'>
                <p>{t("Thanks for signing the contract, We will contact you. From here, you can navigate to your dashboard.")}</p>
                <div className="actions">
                    <button className="button--primary" onClick={() => {
                        navigate(generateRouteUrl('QuotationFinal', { quotationToken, quotationId }))
                    }}>{t("Continue")}</button>
                </div>
            </Modal>
        </div >
    )
}

QuotationSignature.Loader = async ({ params }) => {
    const { quotationToken } = params

    //call quotation details API to check authentication status
    const { project_id, quotation = null } = await getQuotationDetails(quotationToken)

    if (!quotation && project_id) {
        return redirect(generateRouteUrl('Dashboard', { quotationToken, quotationId: quotation?.id }))
    }

    const selectedItems = LocalStorage(quotationToken).get('selectedQuotationItems') || {}

    if (isEmpty(selectedItems)) {
        return redirect(generateRouteUrl('QuotationDetails', { quotationToken }))
    }

    return { selectedItems }
}

export default QuotationSignature
