import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../ui';
import styles from './quotation-item.module.scss';
import { getCmsFileUrl } from '../../library/utilities';
// import { formatPrice } from '../../library/utilities';


const AdditionalQuotationItem = ({ itemDetails, address }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.image}>
                    {itemDetails.product.main_photos && <Image src={getCmsFileUrl(itemDetails.product?.main_photos?.[0])} />}
                    <i>{t('Image is for illustration.Real product may vary')}</i>
                </div>
            </div>
            <div className={styles.right}>
                <h2>{itemDetails.product.name}</h2>
                <p className={styles.addressInfo}>Address Information :</p>
                <table>
                    <tbody>
                        <tr>
                            <td className={styles.tableHeadingPadding}>{t('Quantity')}</td>
                            <td className={styles.tableHeadingPadding}>Address</td>
                        </tr>
                        {address.map((address) => {
                            return <tr className={styles.greenShadowBox}>
                                <td className={styles.tableValuePadding}>{address?.quantity}</td>
                                <td className={styles.tableValuePadding}>{address?.street_number},{address?.street} ,{address?.city} , {address?.county} , {address?.zipcode}</td>
                            </tr>

                        })}

                    </tbody>
                </table>
                {itemDetails.client_comment && <div>
                    <p className={styles.addressInfo}>Notes :</p>
                    <div className={styles.greenShadowBox}><div className={styles.tableValuePadding}>{itemDetails.client_comment}</div></div>
                </div>}
            </div>
        </div>

    )
}

AdditionalQuotationItem.propTypes = {
    itemDetails: PropTypes.object.isRequired,
    address: PropTypes.arrayOf(PropTypes.object)
    // onSelectionChange: PropTypes.func.isRequired,
    // selectedId: PropTypes.number,
    // currency: PropTypes.object
}

// const AdditionalQuotationItemQuantities = ({ prices, onSelectionChange, selectedId = null, currency }) => {
//     const [selected, setSelected] = useState(selectedId)
//     const handleRowSelect = (row) => {
//         const _sel = row.id === selected ? null : row.id
//         setSelected(_sel)
//         onSelectionChange(_sel)
//     }


//     return (
//         <table style={{ width: '100%' }}>
//             <tr >
//                 <td>Quantity</td>
//                 <td>Sales Price/Piece</td>
//                 <td>Total Amount</td>
//                 <td></td>
//             </tr>
//             {prices.map(price => (
//                 <tr key={price.id} onClick={() => handleRowSelect(price)}>
//                     <td className={styles.cell}>{(price.quantity)}</td>
//                     <td className={styles.cell}>  {formatPrice(price.sales_price, currency?.code)}</td>
//                     <td className={styles.cell}> {formatPrice(price.total_price, currency?.code)}</td>
//                     <td className={styles.quantityCheckbox}>
//                         <label htmlFor="" className="checkbox">
//                             <input type="checkbox" checked={selected === price.id} readOnly />
//                             <span className="indicator"></span>
//                         </label>
//                     </td>
//                 </tr >
//             ))}
//         </table>
//     )
// }

// AdditionalQuotationItemQuantities.propTypes = {
//     prices: PropTypes.arrayOf(PropTypes.object).isRequired,
//     onSelectionChange: PropTypes.func.isRequired,
//     selectedId: PropTypes.number,
//     currency: PropTypes.object
// }

export default AdditionalQuotationItem