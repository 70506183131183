import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { Modal } from '../../../components/ui';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { getCountryById } from '../../../library/helper/country';
import { AddOrSubractDays, formatDate, formatPrice } from '../../../library/utilities';
import styles from './additional-quotation-preview.module.scss';

const Address = ({ address }) => (
    <>
        {address.street_number} {address.street}<br />
        {address.city}<br />
        {!!address.county && `${address.county}`}<br />
        {address.zipcode}<br />
        {address.country}<br />
    </>
)

// const DateFormatted = ({ date }) => {
//     const formatted = useMemo(() => {
//         const dateObj = new Date(date)
//         if (isNaN(dateObj)) {
//             return "NA"
//         }
//         return new Intl.DateTimeFormat('de-DE').format(dateObj)
//     }, [date])
//     return <>{formatted}</>
// }
const AdditionalQuotationPreview = () => {
    const { t } = useTranslation()
    const { quotationToken } = useParams()
    const { quotationDetails, item, address, netAmount } = useRouteLoaderData('AdditionalQuotation')
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const modalTimer = setTimeout(() => {
            setIsConfirmModalVisible(true);
        }, 3 * 1000);

        return () => {
            clearTimeout(modalTimer);
        };
    }, []);

    // const selectedItemsTotal = item.items.reduce((acc, { price }) => acc + parseFloat(price.total_price), 0)

    const handleSignClick = () => {
        //Show quotation preview
        navigate(generateRouteUrl('AdditionalQuotationSignature', { quotationToken, itemId: item.id, quotationId: quotationDetails?.id }))
    }

    return (
        <div className="wrapper quotation-wrapper">
            <Modal title={t('Review Quotation')} onClose={() => setIsConfirmModalVisible(false)} isVisible={isConfirmModalVisible}>
                <p>
                    {t('Dear {{first_name}} {{last_name}}, Please review the quotation details and sign it.', {
                        first_name: item.contact.first_name,
                        last_name: item.contact.last_name,
                    })}
                </p>
                <div className="actions button--primary" onClick={() => setIsConfirmModalVisible(false)}>{t('Continue')}</div>
            </Modal>
            <div className="main">
                <div className="inner-wrapper">
                    <div className="inner-wrapper--title">{t('Additional Quotation Final Preview')}</div>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        <div className={styles.header}>
                            <div className={styles.logo}>
                                <img src='/images/logo.svg' alt="Leoprinting" />
                            </div>
                            <img src="/images/tagline.svg" alt="tagline" />
                            {/* <div className={styles.tagline}>
                                From idea to <span className={styles.product}> product</span>
                            </div> */}
                        </div>
                        <div className={`box--large box--light-red`}>
                            <div className="col" style={{ gridRow: "1/3" }}>
                                <h3><b>{item.customer.company}</b></h3>
                                {address.map((address) => {
                                    return <Address key={address.address_id} address={address} />
                                })}
                            </div>
                            <div className="col">
                                <h3>{t('Quotation Reference')}</h3>
                                {String(item.id).padStart(7, '0')}
                            </div>
                            <div className="col">
                                <h3>Contact Name</h3>
                                {item.primary_contact.first_name} {item.primary_contact.last_name}
                            </div>
                            <div className="col">
                                <h3>Quotation Date</h3>
                                {formatDate(quotationDetails.created_at)}
                            </div>
                            <div className="col">
                                <h3>Quotation Valid Upto</h3>
                                {formatDate(AddOrSubractDays(new Date(quotationDetails.created_at), 30, true))}
                            </div>
                        </div>
                        <div className="box--large">
                            <p>{t('Dear {{first_name}} {{last_name}},', {
                                first_name: item.contact.first_name,
                                last_name: item.contact.last_name,
                            })}</p>
                            <p>{t('It is our pleasure to make the following quotation:')}</p>
                        </div>
                        {/* <AdditioanlQuotationItemPreview  item={item} currency={item.currency} /> */}
                    </div>
                </div>
                <div className="inner-wrapper" style={{ marginTop: 'var(--space-xxlarge)' }}>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        {item.client_comment && <div className="box--large box--light-gray">
                            <h3>{t('Client Note')}</h3>
                            {item.client_comment}
                        </div>}
                        <div className="box--light-gray" style={{ gap: 'var(--space-large)' }}>
                            <div className="box--dark-gray col">

                                <h4>{t("File Delivery")}</h4>
                                <p>{t("PDf or EPS file. Any questions? Please contact us")}</p>
                                <p></p>
                                <h4>{t("Delivery")}</h4>
                                <p>{t("Registered delivery to ")}{getCountryById(item.customer.country_id)?.name}</p>
                                <p></p>
                                <h4>{t("Delivery")}</h4>
                                <p>{t("2 to 3 weeks after approval of the proof. A shorter delivery time can be arranged")}.</p>

                            </div>
                            <div className="box--dark-gray col">
                                <h4>{t("Prices")}</h4>
                                <p>{t("Inclusive setup costs")}</p>
                                <p>{t("Inclusive studio support")}</p>
                                <p>{t("Inclusive digital proof for approval")}</p>
                                <p>{t("Exclusive VAT")}</p>
                            </div>
                        </div>
                        <div className="box--large box--light-gray">
                            <p>{t("This quotation is valid for 30 days from the date of stated on the quotation. Please note that additional costs may apply based on Brexit Ruling. Please notify us of any mistakes. Your business is of great value to us and we hope we can be of service to you. The image(s) in this quotation are an indication only, the customised production will deviate from this.")}</p>
                            <h3>{t("Items and Services")}</h3>
                            <div className={styles.totals}>
                                <table className={styles.totalRow}>
                                    <thead>
                                        <tr>
                                            <th>{t('Item Name')}</th>
                                            <th>{t('Description')}</th>
                                            <th></th>
                                            <th>{t('Sales Price/Piece')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {quotationDetails.item_additional_quotation_lines.map((quote) => {

                                            return <tr key={quote?.id}>
                                                <td>{quote?.name}</td>
                                                <td>{quote?.description}</td>
                                                <td></td>
                                                <td>{formatPrice(quote?.sales_price, item.currency?.code)}</td>

                                            </tr>
                                        })}

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan="3">{t('Net Amount')}</th>
                                            <th>{formatPrice(netAmount, item?.currency?.code)}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="box--large box--light-gray">
                            <h3>{t('Signature')}</h3>
                            <p>{t('Before you sign this quote an email must be sent to you to verify your identity.')}</p>
                            <div className={`box--large ${styles.signatureContainer}`}>
                                <div className={styles.contact}>
                                    <div className={styles.name}>{item.primary_contact.first_name} {item.primary_contact.last_name}</div>
                                    <div className={styles.email}>{item.primary_contact.email}</div>
                                </div>
                                <button onClick={handleSignClick} className="button--primary">{t('Click to Sign')}</button>
                            </div>
                            <p><strong>{t('Contact :')}</strong></p>
                            <div className={styles.name}>{item.primary_contact.first_name} {item.primary_contact.last_name}</div>
                            <div className={styles.email}>{item.primary_contact.email}</div>
                        </div>
                        <div className="box center">
                            <p><strong>{t("Passion for creating your excellent promotion.")} </strong></p>
                            <p>{t("80-83 Long Lane, London EC1A 9ET  -  Tel: +44 20 3608 1945  -  E-mail: info@leoprinting.com")}<br />{t("Private Limited Company Number 765685")}</p>
                            <hr style={{ width: '40%', minWidth: '250px' }} />
                            <p>{t("Leoprinting is part of Leogroup")}.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}



export default AdditionalQuotationPreview
