import React from "react";
import { useTranslation } from 'react-i18next';
import styles from '../../../../components/project/project-item.module.scss';


const InProdcution = () => {
    const { t } = useTranslation();
    return <div className={`box--white ${styles.projectItem}`}>
        <div className={`box--plain ${styles.tasksBox}`}>

            <h3 style={{ 'textAlign': 'start' }}>{t("Task For You")}</h3>
            <div className={`box--blue-light ${styles.task}`}>
                <div className={styles.taskContent}>
                    <span>{t("Your order is in production. Leoprinting will update you as soon as it's ready to dispatch")}</span>
                </div>
            </div>
            {/* {item.client_confirm_address === null ? (
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        <span>{t('Fill in the delivery address and confirm ?')}</span>
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={generateRouteUrl('ProjectItemAddress', {
                            quotationToken,
                            itemId: item.id
                        })} className="button--primary">{t('Add Address')}</Link>
                    </div>
                </div>
            ) : (
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        {t('Delivery Address is already confirmed')}
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={generateRouteUrl('ProjectItemAddress', {
                            quotationToken,
                            itemId: item.id
                        })} className="button--primary">{t('View Address')}</Link>
                    </div>
                </div>
            )} */}
        </div>

    </div>
}

export default InProdcution