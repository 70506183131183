import React from 'react';

const renderErrors = (errors) => {
    return errors.map((error, index) =>
        React.isValidElement(error) ? error : <span key={index} className="form-field-error">{error}</span>
    )
}

export const getFieldErrors = (field, errors) => (errors && errors?.[field]) ? renderErrors(errors[field]) : undefined
export const getFieldStatus = (field, errors) => (errors && errors?.[field]) ? "error" : undefined
