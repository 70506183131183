import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, getCmsFileUrl } from '../../library/utilities';
import { Image } from '../ui';
import styles from './quotation-item-preview.module.scss';

const QuotationItemPreview = ({ item, currency }) => {
    const { t } = useTranslation()

    return (
        <div className={`box box--light-gray ${styles.wrapper}`}>
            <div className="box--small">
                <h3>{t('Item Name')}</h3>
                <h2>{item.product.name}</h2>
            </div>
            <div className={styles.item}>
                <div className={styles.left}>
                    <div className="box box--dark-gray">
                        <div className={styles.image}>
                            {item.product.photos && <Image src={getCmsFileUrl(item.product.photos[0])} />}
                        </div>
                        <i>{t('Image is for illustration.Real product may vary')}</i>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className="box box--dark-gray">
                        <h2>{t('Specifications')}</h2>
                        <div className={styles.specifications}>
                            {item.specifications.map(({ name, value }) => (
                                <React.Fragment key={name}>
                                    <div className={styles.name}>{name}</div>
                                    <div className={styles.value}>{value}</div>
                                </React.Fragment>
                            ))}
                        </div>
                        <hr />
                        <div className={styles.quantities}>
                            <div className={`${styles.quantityHeading} ${styles.quantityRow}`}>
                                <div>{t('Quantity')}</div>
                                <div>{t('Sales Price/Piece')}</div>
                                <div>{t('Total Amount')}</div>
                            </div>
                            <div className={styles.quantityRow} key={item.price.id}>
                                <div>{item.price.quantity}</div>
                                <div>{formatPrice(item.price.sales_price, currency.code)}</div>
                                <div>{formatPrice(item.price.total_price_excl_vat, currency.code)}</div>
                            </div >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

QuotationItemPreview.propTypes = {
    item: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired
}

export default QuotationItemPreview