import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './multifile-upload.module.scss';

const getPreviewUrl = (file) => {
    if (/image\/[a-z+.-]+/.test(file.type)) {
        return file.preview
    }
    switch (file.type) {
        case "application/csv":
            return "/icons/filetypes/csv.png"
        default:
            return "/icons/filetypes/csv.png"
    }
}
const SingleFileUpload = forwardRef(function SingleFileUpload(_, ref) {
    const [, setFiles] = useState([])

    // useEffect(() => {
    //     if (!ref.current) {
    //         ref.current = []
    //     }
    //     // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    //     return () => ref.current.forEach(file => URL.revokeObjectURL(file.file.preview));
    // }, []);
    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        maxSize: 10 * 1024 * 1024,
        maxFiles: 10,
        accept: {
            'application/csv': ['.csv'],
            'application/xlsx': ['.xlsx']

        },
        onDropRejected: rejections => {
            alert(rejections.map(({ errors }) => errors.map(({ message }) => message)).join("\n"))
        },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                addFile(file);
            });
        }
    })
    const addFile = (file) => {
        setFiles(files => {
            return [...files, Object.assign(file, {
                preview: URL.createObjectURL(file)
            })]
        })
        ref.current[0] = file

    }

    return (
        <>
            <div className={styles.files}>
                {ref.current.length > 0 && (
                    ref.current.map(file => (
                        <div className={styles.file} key={file.name}>
                            <img
                                src={getPreviewUrl(file)}
                            // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                            <div style={{ width: '200px', overflow: 'hidden' }}>
                                <p className="label">{file.name}</p>
                            </div>

                        </div>
                    ))
                )}
            </div>
            <input type="file" {...getInputProps()} style={{ display: "none" }} />
            <div {...getRootProps({ className: styles.dropArea })}>
                <div className={`button--primary`} style={{ height: 'auto' }}>Add File</div>
            </div>
        </>
    )
})
SingleFileUpload.propTypes = {
    inputName: PropTypes.string,
}
export default SingleFileUpload;
