import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link, useFetcher } from 'react-router-dom';
import styles from '../../../../components/project/project-item.module.scss';
import { Image, MakeArtworkRecievedFiles, Modal, ViewAndDownload } from '../../../../components/ui';
import { FilePreview } from '../../../../components/ui/file-preview.jsx';
import i18n from '../../../../i18n.js';
import { ItemOrderStatus, getSecuredFileUrl } from '../../../../library/constants';
import { generateRouteUrl } from '../../../../library/constants/routes.js';
import { DifferenceBetweenDates, formatDate, subtractDaysFromDate } from '../../../../library/utilities';
import style from '../../client-artwork.module.scss';

const ARTWROK_BUFFER_DAYS = 18

const getPreviewUrl = (file) => {
    const extention = file.split('.').pop()

    switch (extention) {
        case "svg":
            return getSecuredFileUrl(file)
        case "pdf":
            return "/icons/filetypes/pdf.svg"

        default:
            return "/icons/filetypes/file.svg"
    }
}

const ArtworkThumbnail = ({ name, url, comment, quotationToken }) => <div className={style.file} key={name}>
    <Image src={getPreviewUrl(url)} width="100" className={style.historyImg} />
    <p className="label">{name}</p>
    <div style={{ 'width': '25%' }}>
        <p>{comment}</p>
    </div>

    <div style={{ 'width': '20%' }}>
        <div className={`actions--compact ${style.artworkActions}`}>
            <ViewAndDownload quotationToken={quotationToken} url={url} />
        </div>
    </div>


</div>

const ArtworksPreview = ({ documents, quotationToken }) => (
    <div className={style.artworkList}>
        {documents.map(({ name, url, comment }) => <ArtworkThumbnail key={url} url={url} name={name} comment={comment} quotationToken={quotationToken} />)}
    </div >
)
const UploadArtwork = ({ quotationToken, item }) => {
    const { t } = useTranslation();
    const fetcher = useFetcher()
    const [isTemplateModalVisuble, setIsTemplateModalVisible] = useState(false)
    const requestedDeliveryDeadline = item.client_expected_delivery_date ? formatDate(item.client_expected_delivery_date) : i18n.t('time')
    const artworkUploadDeadline = item.deadline_artwork_customer ? formatDate(item.deadline_artwork_customer) : formatDate(subtractDaysFromDate(item.client_expected_delivery_date, ARTWROK_BUFFER_DAYS))
    const daysLeft = item.deadline_artwork_customer ?
        DifferenceBetweenDates(Date.now(), item.deadline_artwork_customer) :
        item.client_expected_delivery_date ?
            DifferenceBetweenDates(Date.now(), subtractDaysFromDate(item.client_expected_delivery_date, ARTWROK_BUFFER_DAYS)) :
            null
    const canSendArtwork = item.order_status !== ItemOrderStatus.MAKE_ARTWORK_BY_LP

    return <div className={`box--white ${styles.projectItem}`} >
        <div className={`box--plain ${styles.tasksBox}`}>
            <h2>{t('Tasks for you')}</h2>
            {item.order_status === ItemOrderStatus.MAKE_ARTWORK_BY_LP &&
                <MakeArtworkRecievedFiles quotationToken={quotationToken} documents={item.documents.make_arwork} itemId={item.id} />
            }

            {item.request_for_template === 1 && (
                <div className={`box--light-red ${styles.task}`}>
                    {t('Leoprinting has been requested to send a template.')}
                </div>
            )}
            {item.request_for_template === 2 && (
                <div className={`box--light-red ${styles.task}`}>
                    <div className={styles.taskContent}>
                        {t('Leoprinting has shared the template')}
                    </div>
                    <div className={styles.taskAction}>
                        <button onClick={() => setIsTemplateModalVisible(true)} className="button--primary">{t("View Template")}</button>
                    </div>
                </div>
            )}
            <div className={`${item.client_expected_delivery_date ? 'box--blue-light' : 'box--light-red'} ${styles.task}`}>
                <div className={styles.taskContent}>
                    <span>
                        <Trans
                            defaults="Expected delivery on <0>{{delivery_deadline}}</0> if artwork is uploaded latest by <0>{{artwork_deadline}}</0>"
                            values={{ delivery_deadline: requestedDeliveryDeadline, artwork_deadline: artworkUploadDeadline }}
                            components={[<strong>date</strong>]}
                        />
                    </span>
                    {!!daysLeft && (
                        <div className={styles.daysLeftBox}>
                            <div className={styles.daysLeftNum}>{daysLeft}</div>
                            <div className={styles.daysLeftSuffix}>{t('Days Left')}</div>
                            <div className={styles.daysLeftText}>{t('to confirm the proof')}</div>
                        </div>
                    )}
                </div>
                <div className={styles.taskAction}>
                    {canSendArtwork && (
                        <>
                            {!item.request_for_template && (
                                <fetcher.Form method="post">
                                    <input type="hidden" name="action" value="requestartworksample" />
                                    <input type="hidden" name="itemId" value={item.id} />
                                    <button type="submit" className="button--primary">{t('Request Artwork Template')}</button>
                                </fetcher.Form>
                            )}

                            <Link to={generateRouteUrl('ClientArtwork', {
                                quotationToken,
                                itemId: item.id,
                            })} className="button--primary">{t('Upload Artwork')}</Link>

                        </>
                    )}
                </div>
            </div>
            {item.client_confirm_address === null ? (
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        <span>{t('Fill in the delivery address and confirm ?')}</span>
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={generateRouteUrl('ProjectItemAddress', {
                            quotationToken,
                            itemId: item.id
                        })} className="button--primary">{t('Add Address')}</Link>
                    </div>
                </div>
            ) : (
                <div className={`box--blue-light ${styles.task}`}>
                    <div className={styles.taskContent}>
                        {t('Delivery Address is already confirmed')}
                    </div>
                    <div className={styles.taskAction}>
                        <Link to={generateRouteUrl('ProjectItemAddress', {
                            quotationToken,
                            itemId: item.id
                        })} className="button--primary">{t('View Address')}</Link>
                    </div>
                </div>
            )}

        </div>
        <Modal
            title={t('Recieved Templates')}
            onClose={() => { setIsTemplateModalVisible(false) }}
            isVisible={isTemplateModalVisuble}
            closeButtonText={t('Close')}>
            <div>
                {!!item?.documents?.template &&
                    item.documents.template.map(file => (
                        <FilePreview key={file.url} name={file.name} path={file.url} comment={file.comment} quotationToken={quotationToken} />
                    ))
                }
            </div>
        </Modal>
    </div >
}
UploadArtwork.propTypes = {
    quotationToken: PropTypes.string.isRequired,
    item: PropTypes.shape({
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            main_photos: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        client_expected_delivery_date: PropTypes.string,
    })
}
export default UploadArtwork