import React, { useState } from "react";
import { Modal } from './index';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getFilePath, viewFilePath } from '../../library/utilities';
import { PDFViewer } from "../layout";


const ViewAndDownload = ({ url, quotationToken }) => {
    const [fileModal, showFileModal] = useState(false)
    return (
        <div>
            <div className={`actions--compact`}>
                <button onClick={() => { showFileModal(true) }} className="button" ><img src="/icons/lens-plus.svg" />View</button>
                <Link reloadDocument to={getFilePath(url, quotationToken)} className="button"><img src="/icons/download.svg" />Download</Link>
            </div>
            <Modal onClose={() => { showFileModal(false) }} isVisible={fileModal}>
                <PDFViewer path={url} type={url.split('.').pop()} />
            </Modal>
        </div>
    )
}

export const ViewBlob = ({ obj }) => {
    // const [fileModal, showFileModal] = useState(false)
    const handleViewFile = (file) => {
        const fileType = file.type;
        var blob = new Blob([file], { type: fileType});
        var blobURL = window.URL.createObjectURL(blob);
        window.open(blobURL);
    }
    return (
        <div>
            <div className={`actions`}>
                <button onClick={() => { handleViewFile(obj) }} className="button" ><img src="/icons/lens-plus.svg" />View</button>
            </div>
            {/* <Modal onClose={() => { showFileModal(false) }} isVisible={fileModal}>
                <PDFViewer path={url} type={type} />
            </Modal> */}
        </div>
    )
}

ViewBlob.propTypes = {
    obj: PropTypes.object,

};

ViewAndDownload.propTypes = {
    url: PropTypes.string,
    quotationToken: PropTypes.string
};
export default ViewAndDownload