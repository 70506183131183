import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useFetcher } from 'react-router-dom';
import styles from '../../../../components/project/project-item.module.scss';
import { Modal } from '../../../../components/ui';
import { FilePreview } from '../../../../components/ui/file-preview';
import { ItemClientStatuses, ItemOrderStatus, generateRouteUrl } from '../../../../library/constants';
import { DifferenceBetweenDates } from '../../../../library/utilities/date.js';

const FinalApproval = ({ quotationToken, item }) => {
    const [rejectComment, setRejectComment] = useState('')
    // const [fileModal,showFileModal]= useState(false)
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)
    const [isMessageVisible, setMessageVisisble] = useState(false)
    const [isRequestChangeModalVisisble, setIsRequestChangeModalVisisble] = useState(false)
    const { t } = useTranslation()
    const fetcher = useFetcher()

    const daysLeft = item.deadline_approval_proof_customer ?
        DifferenceBetweenDates(Date.now(), item.deadline_approval_proof_customer) :
        null

    const requestChange = async () => {
        const payload = { itemId: item.id, comment: rejectComment }
        fetcher.submit({
            ...payload,
            action: 'rejectProof'
        }, {
            method: 'post',
            encType: 'application/json'
        })
    }
    const approveDesign = () => {
        fetcher.submit({
            itemId: item.id,
            action: 'approveProof'
        }, {
            method: 'post',
            encType: 'application/json'
        })
    }

    return (
        <div className={`box--white ${styles.projectItem}`}>
            <h2>{item.product.name}</h2>
            <div className={`box--plain ${styles.tasksBox}`}>
                {item.item_additional_quotations.length > 0 && item.item_additional_quotations[0]?.status === ItemClientStatuses.FINAL_APPROVAL ?
                    <div className={`box--blue-light ${styles.task}`}>
                        <div className={styles.taskContent}>
                            <span>{t("You have received an Additional Quotation")}</span>
                        </div>
                        <div className={styles.taskAction}>
                            <Link to={generateRouteUrl('AdditionalQuotationDetails', {
                                quotationToken,
                                itemId: item.id,
                                quotationId: item.item_additional_quotations[0]?.id
                            })} className="button--primary"> {t("Review")} </Link>
                        </div>
                    </div> : <div></div>
                }
                {item.order_status == ItemOrderStatus.READY_FOR_PRODUCTION ?
                    <div className={`box--light-red ${styles.task}`}>
                        <div className={styles.taskContent}>
                            <span>{t('The Proof has been approved')}
                                {item.client_confirm_address == null && <span>{t('Please go ahead and add delivery address')}</span>}
                            </span>
                        </div>
                    </div>
                    : <div className={styles.innerTaskBox}>
                        <div className={styles.tasksBox}>
                            {!!daysLeft && (
                                <div className={styles.daysLeftBox}>
                                    <div className={styles.daysLeftNum}>{daysLeft}</div>
                                    <div className={styles.daysLeftSuffix}>{t('Days Left')}</div>
                                    <div className={styles.daysLeftText}>{t('to upload the artwork')}</div>
                                </div>
                            )}
                        </div>
                        {item.proof && item.proof.map((proof) => {
                            return (
                                <div key={proof.url}>
                                    <FilePreview name={proof.name} comment={proof.comment} path={proof.url} quotationToken={quotationToken} />
                                </div>
                            )
                        })}
                        <br />
                        {item.order_status === ItemOrderStatus.PROOF_REJECTED_BY_CLIENT ?
                            <div className={`box--light-red ${styles.task}`}>
                                <div className={styles.taskContent}>
                                    <span>{t('The Proof has been Rejected by you')}
                                    </span>
                                </div>
                            </div>
                            : <div className={styles.prrofText}>
                                <h3>{t("Check your design for the following points:")}</h3>
                                <ol>
                                    <li>{t("Is your logo on the right position ?")}</li>
                                    <li>{t("Is the spelling or punctuation correct")}</li>
                                </ol>
                                <hr />
                                <div className={styles.taskAction}>
                                    <button onClick={() => setIsConfirmationModalVisible(true)} className={styles.button}>{t("Approve Design")}</button>
                                </div>
                                <br />
                                <i>{t("on approval, the design will be converted into final proof print. You will then not able to request a change")}</i>
                                <br />
                                <br />
                                <div className={styles.taskAction}>
                                    <button onClick={() => { setIsRequestChangeModalVisisble(true) }} className={styles.button}>{t("Request Change")}</button>
                                </div>
                                <hr />
                                <Modal onClose={() => { setMessageVisisble(false) }} isVisible={isMessageVisible}>
                                    <p>{t("Artwork has been rejected")}</p>
                                    <button className="button--danger button--large" onClick={() => setMessageVisisble(false)}>OK</button>

                                </Modal>
                                <Modal title={t('Request Changes')} onClose={() => { setIsRequestChangeModalVisisble(false) }} isVisible={isRequestChangeModalVisisble}>
                                    <div className={fetcher?.json?.action === 'rejectProof' ? 'loading' : ''}>
                                        <div>
                                            <p>{t("Please tell us what changes you require in the provided artwork")}</p>
                                            <textarea name="changeComment" onChange={(e) => { setRejectComment(e.target.value) }} rows="4" cols="50" />
                                        </div>
                                        <div className="actions">
                                            <button className="button--danger button--large" onClick={() => setIsRequestChangeModalVisisble(false)} disabled={fetcher?.json?.action === 'rejectProof'}>{t("Cancel")}</button>
                                            <button className="button--primary button--large" onClick={requestChange} disabled={fetcher?.json?.action === 'rejectProof'}>{t("Yes")}</button>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal title={t('Approve Design')} onClose={() => { setIsConfirmationModalVisible(false) }} isVisible={isConfirmationModalVisible}>
                                    <div className={fetcher?.json?.action === 'approveProof' ? 'loading' : ''}>
                                        <p>{t('Are you sure you wanted to approve this Proof(s) It\'ll be used for printing')}</p>
                                        {item.proof.map((proof) => <FilePreview key={proof.url} name={proof.name} comment={proof.comment} path={proof.url} quotationToken={quotationToken} />)}
                                        <br />
                                        <div className="actions">
                                            <button className="button--danger button--large" onClick={() => setIsConfirmationModalVisible(false)} disabled={fetcher?.json?.action === 'approveProof'}>{t("Cancel")}</button>
                                            <button className="button--primary button--large" onClick={() => (approveDesign())} disabled={fetcher?.json?.action === 'approveProof'}>{t("Yes")}</button>
                                        </div>
                                    </div>
                                </Modal>
                                {/* <Modal title="Design Approved" onClose={() => { setIsConfirmationModalVisible(false) }} isVisible={isConfirmationModalVisible}>
                            <div>
                                <p>{t("Your design approval has been sent ot Leopritings")}</p>
                            </div>
                            <div className="actions">
                                <button className="button--danger button--large" onClick={() => setIsConfirmationModalVisible(false)}>{t("OK")}</button>

                            </div>
                        </Modal> */}

                            </div>}
                    </div>}
                <br />
                {item.client_confirm_address === null ? (
                    <div className={`box--blue-light ${styles.task}`}>
                        <div className={styles.taskContent}>
                            <span>{t('Fill in the delivery address and confirm ?')}</span>
                        </div>
                        <div className={styles.taskAction}>
                            <Link to={generateRouteUrl('ProjectItemAddress', {
                                quotationToken,
                                itemId: item.id
                            })} className="button--primary">{t('Add Address')}</Link>
                        </div>
                    </div>
                ) : (
                    <div className={`box--blue-light ${styles.task}`}>
                        <div className={styles.taskContent}>
                            {t('Delivery Address is already confirmed')}
                        </div>
                        <div className={styles.taskAction}>
                            <Link to={generateRouteUrl('ProjectItemAddress', {
                                quotationToken,
                                itemId: item.id
                            })} className="button--primary">{t('View Address')}</Link>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}


FinalApproval.propTypes = {
    quotationToken: PropTypes.string.isRequired,
    item: PropTypes.shape({
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            main_photos: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        client_expected_delivery_date: PropTypes.string,
    })
}

export default FinalApproval