import React from 'react';
import { Outlet, useLoaderData, useNavigation, redirect } from 'react-router-dom';
import Header from '../../../components/layout/header.jsx';
import Footer from '../../../components/layout/footer.jsx';
import { HeaderBar } from '../../../components/layout/header-bar.jsx';
import { getProjectItemDetails } from '../../../api/project/project-item.js';
import { getProjectItemAddresses } from '../../../api/project/address.js';
import { getAdditionalQuotationDetails } from '../../../api/quotation/quotation.js';
import { AdditionalQuotationStatusText } from '../../../library/constants/client-statuses.js';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { getProjectDetails } from '../../../api/project/project.js';


const AdditionalQuotationRoot = () => {
    const navigation = useNavigation()
    const { id } = useLoaderData()   //project id fetching

    return (
        <>
            <Header />
            <HeaderBar projectId={id} />
            <div className={`container page ${["loading", "submitting"].includes(navigation.state) ? 'loading' : ''}`}>
                <Outlet />
            </div>
            <Footer />
        </>
    );
}

AdditionalQuotationRoot.Loader = async ({ params }) => {
    const { quotationToken, quotationId } = params
    const item = await getProjectItemDetails(quotationToken, params.itemId)
    const { id } = await getProjectDetails(quotationToken)

    const address = await getProjectItemAddresses(quotationToken, params.itemId)
    const quotationDetails = await getAdditionalQuotationDetails(quotationToken, quotationId)
    if (quotationDetails.status === AdditionalQuotationStatusText.Approved || quotationDetails.status === AdditionalQuotationStatusText.Cancel) {
        return redirect(generateRouteUrl('Dashboard', { quotationToken }))
    }
    let netAmount = 0;
    quotationDetails.item_additional_quotation_lines.forEach(num => {
        netAmount += parseFloat(num?.sales_price);
    })
    // if (!quotation || isEmpty(quotation)) {
    //     return redirect(generateRouteUrl('Dashboard', { quotationToken }))
    // }

    //If items already selected

    // const savedSelections = LocalStorage(quotationToken).get('selectedQuotationItems') || {}
    return { item, address, netAmount, quotationDetails, id }
}

export default AdditionalQuotationRoot
