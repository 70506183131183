import { LocalStorage } from "../../library/utilities/localstorage.js";
import { axiosInstance } from "../common";

const ENDPOINT_VERIFY = `/auth/verify-otp`;
const ENDPOINT_REQUEST = `/auth/generate-otp`;


export const otpLogin = (headers, payload) => axiosInstance.post(ENDPOINT_VERIFY, payload, { headers });
export const requestOtp = (token) => {
  LocalStorage(token).set('lastOtpRequest', Date.now())
  return axiosInstance.post(ENDPOINT_REQUEST, {
    token
  })
}