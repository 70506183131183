import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLoaderData } from 'react-router-dom';
import { getProjectItemDetails } from '../../api/project/project-item.js';
import { ItemPreview } from '../../components/quotation';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { formatPrice } from '../../library/utilities';
import styles from './orderDetails.module.scss';



const OrderDetails = () => {
    const { quotationToken } = useParams()
    const { t } = useTranslation();
    const { item } = useLoaderData()
    return (
        <div className="wrapper item-wrapper">
            <div className="main">
                <div id="PrintableQuotation" className="inner-wrapper">
                    <div className={`inner-wrapper--bigTitle`}>
                        <span>{t("Order Details")}</span>
                        <div className="actions align--right">
                            <Link to={generateRouteUrl('Dashboard', 
                            { quotationToken })} 
                            className="button"><img src="/icons/stats.svg" />{t('Go to Dashboard')}</Link>
                        </div>
                    </div>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        <div className={styles.header}>
                            <div className={styles.logo}>
                                <img src='/images/logo.svg' alt="Leoprinting" />
                            </div>
                            <img src="/images/tagline.svg" alt="tagline" />

                            {/* <div className={styles.tagline}>
                                {t("From idea to product")}
                            </div> */}
                        </div>
                        <div className={`box--large box--light-gray`}>
                            <div className="col" style={{ gridRow: "1/3" }}>
                                <h2><i>{t("Customer")}</i></h2>
                                <h3>{item.customer.company}</h3>
                            </div>

                            <div className="col">
                                <h3><i>{t("Contact Name")}</i></h3>
                                {item.contact.first_name} {item.contact.last_name}
                            </div>


                        </div>

                        {<ItemPreview key={item.id} item={item}  />}
                    </div>
                </div>
                <div className="inner-wrapper" style={{ marginTop: 'var(--space-xxlarge)' }}>
                    <div className={`inner-wrapper--body ${styles.wrapper}`}>
                        <div className="box--large box--light-gray">
                            {item.client_comment && <h3>{t("Client Note")}</h3>}
                            {item.client_comment}
                        </div>
                        {/* <div className="box--light-gray" style={{ gap: 'var(--space-large)' }}>
                            <div className="box--dark-gray col">
                                <h4>{t("File Delivery")}</h4>
                                <p>{t("PDf or EPS file. Any questions? Please contact us")}</p>
                                <p></p>
                                <h4>{t("Delivery")}</h4>
                                <p>{t("Registered delivery to ")}{getCountryById(item.customer.country_id)?.name}</p>
                                <p></p>
                                <h4>{t("Delivery")}</h4>
                                <p>{t("2 to 3 weeks after approval of the proof. A shorter delivery time can be arranged")}.</p>
                            </div>
                            <div className="box--dark-gray col">
                                <h4>{t("Prices")}</h4>
                                <p>{t("Inclusive setup costs")}</p>
                                <p>{t("Inclusive studio support")}</p>
                                <p>{t("Inclusive digital proof for approval")}</p>
                                <p>{t("Exclusive VAT")}</p>
                            </div>
                        </div> */}
                        <div className="box--large box--light-gray">
                            {/* <p>{t("This item is valid for 30 days from the date of stated on the item. Please note that additional costs may apply based on Brexit Ruling. Please notify us of any mistakes. Your business is of great value to us and we hope we can be of service to you. The image(s) in this item are an indication only, the customised production will deviate from this.")}</p> */}
                            <h3>{t("Items and Services")}</h3>
                            <div className={styles.totals}>
                                <table className={styles.totalRow}>
                                    <thead>
                                        <tr>
                                            <th>{t("Item Name")}</th>
                                            <th>{t("Quantity")}</th>
                                            <th>{t("Approved Price/Piece")}</th>
                                            <th>{t("Total Price")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>{item.product.name}</td>
                                            <td>{item.quantity}</td>
                                            <td>{formatPrice(item.approved_unit_price, item.currency.code)}</td>
                                            <td>{formatPrice((parseFloat(item.approved_unit_price) * parseInt(item.quantity)), item.currency.code)}</td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className="box--large box--light-gray">

                            <p><strong>{t("Contact")} :</strong></p>
                            <div className={styles.name}>{item.contact.first_name} {item.contact.last_name}</div>
                            <div className={styles.email}>{item.contact.email}</div>
                        </div>
                        {/* <div className="box center">
                            <p><strong>{t("Passion for creating your excellent promotion.")} </strong></p>
                            <p>{t("80-83 Long Lane, London EC1A 9ET  -  Tel: +44 20 3608 1945  -  E-mail: info@leoprinting.com")}<br />{t("Private Limited Company Number 765685")}</p>
                            <hr style={{ width: '40%', minWidth: '250px' }} />
                            <p>{t("Leoprinting is part of Leogroup")}.</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    )
}


OrderDetails.Loader = async ({ params }) => {
    const { quotationToken, itemId } = params
    const  item = await getProjectItemDetails(quotationToken, itemId)
    return { item }
}

export default OrderDetails
