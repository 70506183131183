import PropTypes from 'prop-types';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getFilePath, viewFilePath } from '../../library/utilities';
import { PDFViewer } from "../layout";
import { Modal } from './index';

const FileActions = ({ url, quotationToken }) => {
    const [fileModal, showFileModal] = useState(false)
    const { t } = useTranslation()
    const printHandler = (url) => {
        let iframe = document.getElementById('printframe');
        if (!iframe) {
            iframe = document.createElement('iframe')
            iframe.id = 'printframe'
        }
        // Set the source of the iframe to the external file (e.g., a PDF)
        iframe.src = url;

        // Hide the iframe (optional)
        iframe.style.display = 'none';

        // Append the iframe to the body
        document.body.appendChild(iframe);

        // Wait for the iframe to load and then print
        iframe.onload = function () {
            iframe.contentWindow.focus();  // Focus the iframe content
            iframe.contentWindow.print();  // Trigger the print dialog
        };
    }
    return (
        <div>
            <div className={`actions--compact`}>
                <button onClick={() => printHandler(viewFilePath(url, quotationToken))} className="button"><img src="/icons/print.svg" />{t("Print")}</button>
                <button onClick={() => { showFileModal(true) }} className="button" ><img src="/icons/lens-plus.svg" />{t("View")}</button>
                <Link reloadDocument to={getFilePath(url, quotationToken)} className="button"><img src="/icons/download.svg" />{t("Download")}</Link>
            </div>
            <Modal title="Preview" onClose={() => { showFileModal(false) }} isVisible={fileModal}>
                <PDFViewer path={url} type={url.split('.').pop()} />
            </Modal>
        </div>
    )
}

FileActions.propTypes = {
    url: PropTypes.string,
    quotationToken: PropTypes.string
};
export default FileActions