import { axiosInstance } from "../common";
import { generatePath } from 'react-router-dom'
const MAIN_ENDPOINT = `/additional-quotation/:token/:quotationId`;



// export const approveAdditionalQuotation = (token, quotationId, signatureId) => axiosInstance.post(MAIN_ENDPOINT, {
//     signature: signatureId,
// }, {
//     params: {
//         token,
//         quotationId

//     }
// }).then(({ data }) => data)





export const approveAdditionalQuotation = (token, quotationId, signatureId) => axiosInstance.post(generatePath(MAIN_ENDPOINT, { token: token, quotationId: quotationId }),
    { signature: signatureId },


).then(({ message }) => message)