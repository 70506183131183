import dayjs from 'dayjs'
import { formats } from "../constants/formats.js"

export const formatPrice = (price, currency) => {
    try {
        const currencyCode = currency.toUpperCase()
        const locale = (currencyCode === 'GBP') ? 'en-UK' : currencyCode === 'USD' ? 'en-US' : 'nl-NL'
        const money = Intl.NumberFormat(locale,
            { style: 'currency', currency: currencyCode }
        ).format([price])
        return money
    }
    catch (error) {
        console.debug({ price, currency })
        console.error(error)
        return '⚠' + price
    }
}

export const formatDate = (stringDate) => {
    if (!stringDate) {
        return ''
    }
    return dayjs(stringDate, formats.backendDateFormat).format(formats.frontendDateFormat)
}

export const getDayOfWeek = (stringDate) => {
    if (!stringDate) {
        return ''
    }
    return dayjs(stringDate, formats.backendDateFormat).format('dddd')
}

export const formatDateTime = (stringDate) => {
    if (!stringDate) {
        return ''
    }
    return dayjs(stringDate, formats.backendDateTimeFormat).format(formats.frontendDateTimeFormat)
}

export const formatBackendDate = (stringDate) => {
    console.log(stringDate)
    if (!stringDate) {
        return ''
    }
    return dayjs(stringDate, formats.frontendDateFormat).format(formats.backendDateFormat)
}

export const getTimeAgo = (stringDate) => {
    return dayjs(stringDate, formats.backendDateTimeFormat).fromNow()
}