import React from "react";
import { useTranslation } from 'react-i18next';
import styles from '../../../../components/project/project-item.module.scss';




const InTransit = () => {
    const { t } = useTranslation();
    const time = '5-6 days'
    return <div className={`box--white ${styles.projectItem}`}>

        <div className={`box--plain ${styles.tasksBox}`}>
            <h3 style={{ 'textAlign': 'start' }}>{t('Task For You')}</h3>
            <div className={`box--blue-light ${styles.task}`}>
                <div className={styles.taskContent}>
                    <span>{t("The order is shipped.  The Delivery will take")} {time} {t("to reach")}</span>
                </div>
            </div>
        </div>
    </div>
}

export default InTransit