import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './progress-bar.module.scss';

const ProgressBar = ({ value, size = 'inherit' }) => {
    const { t } = useTranslation();
    const pieCss = useMemo(() => {
        if (value > 50) {
            return {
                clip: 'rect(auto, auto, auto, auto)'
            }
        }
        else {
            return {
                clip: 'rect(0, 1em, 1em, 0.5em)'
            }
        }
    }, [value])

    const unfilledHalfCss = useMemo(() => {
        if (value > 50) {
            return {
                transform: 'rotate(180deg)'
            }
        }
        else {
            return {
                transform: 'rotate(0deg)'
            }
        }
    }, [value])

    if (value < 0) {
        return
    }

    return (
        <div className={styles.pieWrapper} style={{ fontSize: size }}>
            <span className={styles.label}>
                <span className={styles.value}>{value}%</span>
                <span className={styles.smalltext}>{t('completed')}</span>
            </span>
            <div className={styles.pie} style={pieCss}>
                <div className={styles.halfCircle} style={unfilledHalfCss}></div>
                <div className={styles['halfCircle--filled']} style={{ transform: `rotate(${value * 3.6}deg)` }}></div>
            </div>
            <div className={styles.shadow}></div>
        </div>
    )
}

ProgressBar.propTypes = {
    size: PropTypes.string,
    value: (props, propName, componentName) => {
        const value = props[propName];
        if (value === undefined || (value < -1 || value > 100)) {
            return new Error(
                `Invalid prop '${propName}' supplied to '${componentName}'. '${propName}' must be an integer between 0 and 100.`
            );
        }
    }
}

export default ProgressBar