import PropTypes from 'prop-types'
import React from 'react'
import styles from './modal.module.scss'

const Modal = ({ isVisible = false, title = "", width = '768px', onClose, closeButtonText, onOk, oKButtonText, children }) => {
    if (!isVisible) {
        return
    }

    return (
        <>
            <div className={styles.Overlay} onClick={onClose}></div>
            <dialog className={styles.Wrapper} style={{ width }} open>
                <div className={styles.Header}>
                    <div className={styles.Title}>{title}</div>
                    {onClose != null && <div className={styles.Close} onClick={onClose}>
                        <img src="/icons/close.svg" />
                    </div>}
                </div>
                <div className={styles.Body}>
                    <div className={`box ${styles.ContentBox}`}>
                        {children}
                    </div>
                </div>
                {!!closeButtonText && (
                    <div className={styles.Footer}>
                        <buttton className="button--danger" onClick={onClose}>
                            {closeButtonText}
                        </buttton>
                    </div>
                )}
                {!!oKButtonText && (
                    <div className={styles.Footer}>
                        <buttton className="button--danger" onClick={onOk}>
                            {oKButtonText}
                        </buttton>
                    </div>
                )}
            </dialog>
        </>
    )
}

Modal.propTypes = {
    isVisible: PropTypes.bool,
    title: PropTypes.string,
    width: PropTypes.string,
    onClose: PropTypes.func,
    closeButtonText: PropTypes.string,
    onOk: PropTypes.func,
    oKButtonText: PropTypes.string,
    children: PropTypes.node.isRequired
}

export default Modal