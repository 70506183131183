import React, { useState } from 'react';
import { Link, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { deleteQuotation } from '../../../api/quotation/quotation.js';
import { AdditionalQuotationItem } from '../../../components/quotation';
import { Modal } from '../../../components/ui';
import { generateRouteUrl } from '../../../library/constants/routes.js';
import { formatPrice, getFilePath } from '../../../library/utilities';
// import { LocalStorage } from '../../../library/utilities/localstorage.js';
import { useTranslation } from 'react-i18next';
import styles from './additional-quotation.module.scss';

const AdditionalQuotationDetails = () => {
    const { quotationToken } = useParams()
    const { quotationDetails, item, address, netAmount } = useRouteLoaderData('AdditionalQuotation')
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)
    const [rejectCommentVisible, setRejectCommentVisible] = useState(false)
    const [rejectComment, setRejectComment] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const handleConfirmClick = () => {
        gotoOrderConfirm()
    }

    const handleRejectQuotation = async () => {
        try {
            const message = await deleteQuotation(quotationToken, rejectComment)
            console.log(message)
        } catch (e) {
            console.log(e);
            // alert(e.message)
        }

    }
    const gotoOrderConfirm = () => {
        //redirect to quotation preview, with selected data
        navigate(generateRouteUrl('AdditionalQuotationPreview', { quotationToken, itemId: item.id, quotationId: quotationDetails.id }))
    }
    return (
        <div className="wrapper quotation-wrapper">
            <div className="main">
                <h2>{t("Additional Quotation")}</h2>
                <AdditionalQuotationItem itemDetails={item} address={address} />
            </div>
            <div className="sidebar">
                <div className="box--gray">
                    <h3>{t("Summary")}</h3>
                    <div className={styles.totals}>
                        <table className={styles.totalRow}>
                            <thead>
                                <tr>
                                    <td className={styles.tableHeading}>{t("Item Name")}</td>
                                    <td className={styles.tableHeading}>{t("Cost")}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    quotationDetails.item_additional_quotation_lines.map((line) => {
                                        return <tr key={line?.id}>
                                            <td className={styles.name}>{line?.name}<br /> <span className={styles.desc}>{line?.description}</span> </td>
                                            <td className={styles.tableValuePadding}>{formatPrice(line?.sales_price, item?.currency?.code)}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{t("Net Amount")}</td>
                                    <td> {formatPrice(netAmount, item?.currency?.code)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="actions--expand">
                    <button className="button--primary button--large" onClick={handleConfirmClick} >{t("Confirm Additional Cost")}</button>
                    {/* <button className="button--large" onClick={() => { setRejectCommentVisible(true) }}>Reject</button> */}
                </div>
                <Link to={getFilePath(quotationDetails.pdf?.quotation, quotationToken)} className={styles.quotationDownload} reloadDocument download target="_self">
                    <div className={styles.quotationDownload__text}>
                        <h3>{t("Download Additional Quotation PDF")}</h3>

                    </div>
                    <img src="/icons/pdf.svg" className={styles.quotationDownload__icon} />
                </Link>
                <Modal title={t('Confirm Order')} onClose={() => { setIsConfirmationModalVisible(false) }} isVisible={isConfirmationModalVisible}>
                    <p>{t("Not all items are selected. Are you sure you want to continue")} ?</p>
                    <div className="actions">
                        <button className="button--danger button--large" onClick={() => setIsConfirmationModalVisible(false)}>{t("Cancel")}</button>
                        <button className="button--primary button--large" onClick={() => gotoOrderConfirm()}>{t("Yes")}</button>
                    </div>
                </Modal>

                <Modal title={t('Reject Reason')} onClose={() => { setRejectCommentVisible(false) }} isVisible={rejectCommentVisible}>
                    <p>{t("Please state a reason, for rejection")}</p>
                    <input type="text" onChange={(e) => { setRejectComment(e.target.value) }} />
                    <div className="actions">
                        <button className="button--danger button--large" onClick={() => setRejectCommentVisible(false)}>{t("Cancel")}</button>
                        <button className="button--primary button--large" onClick={() => { handleRejectQuotation() }}>{t("Yes")}</button>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default AdditionalQuotationDetails
