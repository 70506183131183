import { generatePath } from "react-router-dom";
import { axiosInstance } from "../common";

const ENDPOINT = `/item/:itemId/addresses`;
const ENDPOINT_BULK = `item/:itemId/bulk-upload-address`;

export const getProjectItemAddresses = (token, itemId) => axiosInstance.get(generatePath(ENDPOINT, {
    itemId
}), {
    params: {
        token
    }
}).then(({ data }) => data)

export const updateProjectItemAddresses = (token, itemId, addresses) => axiosInstance.post(generatePath(ENDPOINT, {
    itemId
}), {
    delivery_addresses: addresses
}, {
    params: {
        token
    }
}).then(({ status }) => status)

export const bulkUploadProjectItemAddresses = (token, itemId, fileId) => axiosInstance.post(generatePath(ENDPOINT_BULK, {
    itemId
}), {
    file_id: fileId
}, {
    params: {
        token
    }
}).then(({ status }) => status)