import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { generateRouteUrl, getPublicFileUrl, isAuthenticated } from '../../library/constants';
import styles from './header-bar.module.scss';

const HeaderBar = ({ menu, projectId, language = 'en' }) => {
    const { t } = useTranslation()
    const { quotationToken } = useParams()
    const location = useLocation()
    const isVisible = location.pathname.includes("dashboard") || location.pathname.includes("files") || location.pathname.includes("additional-quotation")

    return (
        <div className={`container ${styles.headerBar}`}>
            <>
                <div className={styles.nav}>{menu}</div>
                {!!projectId && (
                    <div className={styles.projectNumber}>
                        {isAuthenticated(quotationToken) && isVisible ? <div className={styles.topRight}>
                            <NavLink className={({ isActive, isPending }) =>
                                isPending ? styles.active : isActive ? styles.active : styles.inactive
                            } to={generateRouteUrl('Dashboard', {
                                quotationToken,
                            })} > <button className="button" ><img src="/icons/lens-plus.svg" alt="downlaod" />{t("Dashboard")}</button></NavLink>
                            <NavLink className={({ isActive, isPending }) =>
                                isPending ? styles.active : isActive ? styles.active : styles.inactive
                            } to={generateRouteUrl('Files', {
                                quotationToken,
                            })}><button className="button" ><img src="/icons/lens-plus.svg" alt="lens" />{t("Files")}</button></NavLink>
                        </div> : <div></div>}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                            <div>
                                <img src="/icons/box.svg" alt="box" style={{ marginRight: '5px' }} />
                                {t('Project ID')}: <strong>{String(projectId)}</strong>
                            </div>
                            <img src={getPublicFileUrl(`upload/flag/${language}.png`)} alt={language} style={{ width: 'auto', height: '1rem' }} />
                        </div>
                    </div>
                )}
            </>
        </div>
    );
}

HeaderBar.propTypes = {
    menu: PropTypes.node,
    projectId: PropTypes.node,
}

export { HeaderBar };
