import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from 'react-i18next';
import styles from '../project/project-item.module.scss';
import './style.css';
import { ItemClientStatuses } from '../../library/constants/client-statuses.js';

function checkStatus(index, currentValue) {
    if (index < currentValue) return "completed"
    else if (index == currentValue) return "active"
    else return


}

const Steps = ({ current_step, stateChanger, items }) => {
    const { t } = useTranslation()
    if (!items.map(({ index }) => index).includes(current_step)) {
        return
    }
    return (
        <div className="progress-wrapper">
            <div className="progress-ul">
                {items.map((data, index) => {
                    return (
                        <li className="progress-step" key={index}>
                            <div className="progress-content"> {data.index === ItemClientStatuses.IN_TRANSIT ?
                                <div>{data.title} <div className={styles.tooltip}> <img src="/icons/info.svg" />
                                    <span className={styles.tooltiptext}><p>{t('Order is Shipped')}</p></span>
                                </div></div> : <p>{data.title}</p>

                            } </div>
                            <div className="progress-indicator-wrapper">
                                <a className={"progress-middle " + checkStatus(data.index, current_step)} onClick={() => { stateChanger(data.index) }}>
                                    <div className="progress-inner">
                                        {data.icon}
                                    </div>
                                </a>
                            </div>
                        </li>
                    )
                })}
            </div>
        </div>
    )

}



Steps.propTypes = {
    current_step: PropTypes.number,
    stateChanger: PropTypes.func,
    items: PropTypes.array
}
export default Steps