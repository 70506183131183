export const ItemClientStatuses = Object.freeze({
    ORDER_CONFIRMATION: 1,
    UPLOAD_ARTWORK: 2,
    FINAL_APPROVAL: 3,
    IN_PRODUCTION: 4,
    IN_TRANSIT: 5,
    DELIVERED: 6,
    CANCELLED: 10,
})

export const AdditionalQuotationStatusText = {
    Approved: 4,
    Sent: 3,
    Cancel: 2,
    New: 1
}

export const ItemOrderStatus = Object.freeze({
    NEW: 1,
    MAKE_ARTWORK_BY_LP: 9,
    ARTWORK_RECEIVED: 11,
    ARTWORK_REJECTED: 12,
    ARTWORK_SEND_TO_STUDIO: 13,
    ARTWORK_READY: 14,
    REQUEST_VENDOR_FOR_PROOF: 15,
    PROOF_RECEIVED: 16,
    PROOF_REJECTED: 17, // status change api
    PROOF_SENT_TO_STUDIO: 18,
    PROOF_READY: 19,
    PROOF_SENT_TO_CLIENT: 20,
    PROOF_REJECTED_BY_CLIENT: 21,
    //PRODUCTION
    READY_FOR_PRODUCTION: 22,
    IN_PRODUCTION: 23,
})

export const QuotationStatuses = {
    ACTIVE: 1,
    CANCELLED: 2,
    REJECTED: 4,
}
