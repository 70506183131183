
import React, { useState } from 'react';
import { Link, useLoaderData, useParams } from 'react-router-dom';
import { generateRouteUrl } from '../../library/constants/routes.js';
import styles from './help-and-support.module.scss';
// import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Image } from '../../components/ui';
import { getProjectItemDetails } from '../../api/project/project-item.js';
import { useTranslation } from 'react-i18next'
import { ReasonForComplaint } from './index.js'
import { getSecuredFileUrl } from '../../library/constants';

const getPreviewUrl = (file) => {
    const extention = file.split('.').pop()

    switch (extention) {
        case "svg":
            return getSecuredFileUrl(file)
        case "pdf":
            return "/icons/filetypes/pdf.svg"

        default:
            return "/icons/filetypes/file.svg"
    }
}

const ShareYourFeedback = () => {
    const { quotationToken } = useParams()
    const { t } = useTranslation();



    const { projectItem } = useLoaderData()
    console.log(projectItem);
    return (
        <div className="wrapper ShareYourFeedback-wrapper">
            <div className="main">
                <h2 className="main--title">
                    <img src="/icons/stats.svg" alt="" />
                    {t("Share your feedback")}
                </h2>
                <div className={`box--white ${styles.wrapper}`}>
                    <div className={styles.header}>
                        <h3>{t("Name of the template")}</h3>

                        <Link to={generateRouteUrl('Dashboard', {
                            quotationToken,
                        })} className={styles.button}> <img src="/icons/arrow.svg" alt="back" />{t("Back")}</Link>


                    </div>
                    <div className={styles.innerTaskBox}>
                        <div className={styles.imagebox}>
                            <div className={styles.productInfo}>
                                <Image src={getPreviewUrl(projectItem.proof[0].url)} width="250" className={styles.historyImg} />
                                <p className="label">{projectItem.proof[0].name}</p>
                                <div>
                                    <div className={`actions--compact ${styles.artworkActions}`}>
                                        <button className="button"><img src="/icons/download.svg" />{t("View")}</button>
                                        <Link to={getSecuredFileUrl(projectItem.proof[0].url)} className="button" download target="_blank"><img src="/icons/lens-plus.svg" />{t("Download")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.greyBox}>
                                <p>{t("Hello, we do everything we can to deliver the products quickly and in good quality. We are sorry that this order did not work. We kindly ask you to submit your feedback via this form so that we can find a solution together.")}
                                </p>
                            </div>
                            <div style={{ width: '100%', margin: '16px' }}>
                                <button className={styles.comlaintButtons}>{t("Reason for Complaint")}</button>
                                <button className={styles.comlaintButtons}>{t("Desired Solution")}</button>
                                <button className={styles.comlaintButtons}>{t("Contact Person")}</button>
                            </div>

                            <div className={styles.greyBox}>
                                <p>{t("Please Select the readon for your comlaint")}
                                </p>
                                <ReasonForComplaint />


                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.outerHelpBox}>
                        <div className={styles.helpBox}> <QuestionCircleOutlined style={{fontSize:'32px'}}/><h3>Do you need help ?</h3></div>
                        <div className={styles.helpBox}> <ExclamationCircleOutlined style={{fontSize:'32px'}} /><h3>Do you have a complain ?</h3></div>
                    </div> */}
                </div>


            </div>
        </div>
    )
}

ShareYourFeedback.Loader = async ({ params }) => {
    const { quotationToken, itemId } = params
    const projectItem = await getProjectItemDetails(quotationToken, itemId)
    return { projectItem }

}


export default ShareYourFeedback