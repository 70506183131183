import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom'
import { viewFilePath } from '../../library/utilities';

const PDFViewer = ({ path, type }) => {
    const { quotationToken } = useParams()
    if (type === "pdf") {
        return <iframe title="Preview"
            src={viewFilePath(path, quotationToken)} width="100%"
            height="500px">
        </iframe>
    }
    else if (type === "svg") {
        return (
            <img src={viewFilePath(path, quotationToken)} alt="preview" width="100%"
                height="500px" />
        )
    }
    else {
        return (
            <object
                //type='application/pdf'
                data={viewFilePath(path, quotationToken)} // Replace with the path to your PDF file
                width="100%"
                height="500px">
            </object>

        );
    }

}


PDFViewer.propTypes = {
    path: PropTypes.string,
    type: PropTypes.string,
    project_id: PropTypes.number,

}

export default PDFViewer