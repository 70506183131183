import { generatePath, redirect } from 'react-router-dom';

export const LoginProvider = {
  OTP: 'otp',
}

export const isAuthenticated = (token) => {
  if (!token) {
    return false
  }
  const user = localStorage.getItem('USER') ? JSON.parse(localStorage.getItem('USER')) : null
  return (user?.token === token)
  /**
   * Todo: Check user schema also
   */
}

export const getStoredToken = () => {
  const { token = null } = JSON.parse(localStorage.getItem('USER'))
  if (!token) {
    throw new Response("Not logged in", { status: 200 })
  }
  return token
}

export const getUser = (token) => {
  if (!isAuthenticated(token)) {
    return
  }
  return JSON.parse(localStorage.getItem('USER'))
}

export const redirectToLogin = (token, referrer = '') => {
  let redirectUrl = generatePath('/authenticate/:quotationToken', {
    quotationToken: token
  })

  if (referrer) {
    let params = new URLSearchParams();
    params.set("from", referrer);
    redirectUrl = redirectUrl + "?" + params.toString()
  }
  return redirect(redirectUrl)
}