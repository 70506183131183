import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './footer.module.scss';

const Footer = () => {
    const { t } = useTranslation()
    return (
        <footer>
            <div className="container">
                <div className={styles.footerWrapper}>
                    {t('Copyright © 2008-2023 Leoprinting.  Read our Terms and Conditions.  Go to Leoprinting website')}
                </div>
            </div>
        </footer>
    )
}

export default Footer