import { generatePath } from "react-router-dom";
import { axiosInstance } from "../common";

const ENDPOINT = `/item/:itemId/:action`;




export const approveArtworkMadeByLP = (token, itemId) => axiosInstance.post(generatePath(ENDPOINT, {
    itemId,
    action: 'approved-make-artwork'
}),
    {}, {
    params: {
        token
    }
}).then(({ message }) => message)


export const rejectArtworkMadeByLP = (token, itemId,payload) => axiosInstance.post(generatePath(ENDPOINT, {
    itemId,
    action: 'reject-make-artwork'
}), payload , {
    params: {
        token
    }
}).then(({ message }) => message)