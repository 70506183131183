import PropTypes from 'prop-types';
import React from 'react';

const Selectable = ({ items, renderItem, onSelectionChange, selected = null, ...otherProps }) => {
    return (
        <div {...otherProps}>
            {items.map(item => (
                <div
                    key={item.id}
                    className={`${selected === item.id ? 'selectableItem selected' : 'selectableItem'}`}
                    onClick={() => onSelectionChange(item.id)}
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    {renderItem(item, selected === item.id)}
                </div>
            ))}
        </div>
    )
}

Selectable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default Selectable