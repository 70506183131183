import i18n from "i18next";
import Backend from "i18next-locize-backend";
// import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//   en: {
//     translation: import('./locales/en/common.json')
//   },
//   de: {
//     translation: import('./locales/de/common.json')
//   },
//   fr: {
//     translation: import('./locales/fr/common.json')
//   },
// };

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: 'en',
  allowedAddOrUpdateHosts: ['localhost', 'clientdemo.leoprinting.com'],
}

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    // resources: {},
    lng: 'en',
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: 'common',
    defaultNS: 'common',
    ns: 'common',
    defaultNS: 'common',
    saveMissing: true,
    //lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath: '/locales/{{lng}}/{{ns}}.json'
    // }
    backend: locizeOptions
  });

export default i18n;