import PropTypes from 'prop-types';
import React from 'react';
import { Footer, Header } from '.';
import { HeaderBar } from './header-bar.jsx';

const Page = ({ children, projectId = null, className, ...props }) => (
    <>
        <Header />
        <HeaderBar projectId={projectId} />
        <div className={['container page', className].join(' ')} {...props}>
            {children}
        </div>
        <Footer />
    </>
)

Page.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    projectId: PropTypes.string
}

export default Page