import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './tabs.module.scss'

const Tab = ({ activeTab, name, label, onClick }) => {
    const onTabClick = () => {
        onClick(name);
    };

    return (
        <div className={`${styles.tabItem} ${activeTab === name ? styles.tabItemActive : ''}`} onClick={onTabClick}>
            {label}
        </div>
    );
};
Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};

const Tabs = ({ onTabChange, children }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.name);

    const onClickTabItem = (tab) => {
        setActiveTab(tab)
        if (typeof onTabChange === 'function') {
            onTabChange(tab)
        }
    };

    return (
        <div className={styles.tabs}>
            <div className={styles.tablList}>
                {children.map((child) => {
                    const { name, label } = child.props;
                    return (
                        <Tab
                            activeTab={activeTab}
                            key={name}
                            name={name}
                            label={label}
                            onClick={onClickTabItem}
                        />
                    );
                })}
            </div>
            <div className="tab-content">
                {children.map((child) => {
                    if (child.props.name !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    onTabChange: PropTypes.func,
    children: PropTypes.instanceOf(Array).isRequired,
};

export default Tabs