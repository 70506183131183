import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// // Function to load translations lazily
// const loadTranslation = async (language) => {
//   const translations = await import(`./locales/${language}/common.json`);
//   i18n.addResourceBundle(language, 'translation', translations, true, true);
//   return translations;
// };

// // Example of changing the language and loading translations lazily
// export const changeLanguage = async (newLanguage) => {
//   await loadTranslation(newLanguage);
//   i18n.changeLanguage(newLanguage);
// };

// console.log({ preferredLanguage })
// changeLanguage(preferredLanguage)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
