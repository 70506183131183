import { message } from "antd";
import { useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Form, redirect, useActionData, useLocation, useNavigate, useNavigation, useSubmit } from "react-router-dom";
import { addCustomerAddress } from "../../api/customer/address.js";
import i18n from "../../i18n.js";
import { ValidationError } from "../../library/classes/exceptions/index.js";
import Countries from "../../library/dynamics/countries";
import { getFieldErrors, getFieldStatus } from "../../library/helper/validation-helper.js";
import Modal from "../ui/modal.jsx";
import styles from "./add-address-form.module.scss";

const getFieldStatusClass = (name, errors) => {
  const status = getFieldStatus(name, errors)
  if (!status) {
    return ''
  }
  return `field-status-${status}`
}

const AddAddressForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const formRef = useRef(null)
  const submit = useSubmit()
  const navigation = useNavigation()
  const location = useLocation()
  const { errors } = useActionData() || {}
  const isLoading = navigation.formAction === location.pathname

  return (
    <Modal
      title={t('Add New Address')}
      isVisible={true}
      onClose={() => {
        navigate(-1);
      }}
      oKButtonText={!isLoading ? t('Add New Address') : false}
      onOk={() => {
        submit(formRef.current, {
          method: 'post'
        })
      }}
    >
      <Form ref={formRef} className={`${styles.wrapper}${isLoading ? ' loading' : ''}`} method="post" id="addAddressForm">
        <div className={styles.formItem}>
          <label htmlFor="street">{t('Street')} *</label>
          <input name="street" type="text" id="street" className={getFieldStatusClass('street', errors)} />
          {getFieldErrors('street', errors)}
        </div>
        <div className={styles.formItem}>
          <label htmlFor="street_number">{t('Street Number')} *</label>
          <input name="street_number" type="text" id="street_number" className={getFieldStatusClass('street_number', errors)} />
          {getFieldErrors('street_number', errors)}
        </div>
        <div className={styles.formItem}>
          <label htmlFor="city">{t('City')}</label>
          <input name="city" id="city" type="text" className={getFieldStatusClass('city', errors)} />
          {getFieldErrors('city', errors)}
        </div>
        <div className={styles.formItem}>
          <label htmlFor="county">{t('County')}</label>
          <input name="county" id="county" type="text" className={getFieldStatusClass('county', errors)} />
          {getFieldErrors('county', errors)}
        </div>
        <div className={styles.formItem}>
          <label htmlFor="country">{t('Country')} *</label>
          <select name="country_id" id="country" className={getFieldStatusClass('country_id', errors)} >
            {Countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
          {getFieldErrors('country_id', errors)}
        </div>
        <div className={styles.formItem}>
          <label htmlFor="zipcode">{t('Zipcode')} *</label>
          <input name="zipcode"
            type="text"
            id="zipcode"
            className={getFieldStatus('zipcode', errors)}
          />
          {getFieldErrors('zipcode', errors)}
        </div>
      </Form>
    </Modal >
  );
};

AddAddressForm.Action = async ({ params, request }) => {
  const { quotationToken } = params;
  const data = Object.fromEntries(await request.formData());
  try {
    if (!data.street || data.street.length === 0) {
      throw new ValidationError(i18n.t('Street is required'), [{
        path: 'street',
        message: 'Street is required'
      }])
    }
    if (!data.street_number || data.street_number.length === 0) {
      throw new ValidationError(i18n.t('Street Number is required'), [{
        path: 'street_number',
        message: 'Street Number is required'
      }])
    }
    if (!data.country_id) {
      throw new ValidationError(i18n.t('Country is required'), [{
        path: 'country_id',
        message: 'Country is required'
      }])
    }
    if (!data.zipcode || data.zipcode.length === 0) {
      throw new ValidationError(i18n.t('Zipcode is required'), [{
        path: 'zipcode',
        message: 'Zipcode is required'
      }])
    }
    await addCustomerAddress(quotationToken, data);
    return redirect("../");
  } catch (error) {
    message.error(i18n.t(error.message))
    if (error instanceof ValidationError) {
      return {
        errors: error.errors
      }
    }
    return false
  }
};

export default AddAddressForm;
