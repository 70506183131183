import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCountryById } from '../../library/helper/country.js';
import styles from './address-box.module.scss';

const AddressBox = ({ address, isSelected = false, className }) => {
    const { t } = useTranslation()

    return (
        <div className={`${styles.addressBox} ${className ?? ''} ${isSelected ? styles.addressBoxSelected : ''}`} >
            <div className={styles.header}>{address?.street}</div>
            <div className={styles.content}>
                <div className={styles.field}>
                    <span className={styles.fieldLabel}>{t('Street')}: </span>
                    <span className={styles.fieldValue}>{address?.street}</span>
                </div>
                <div className={styles.field}>
                    <span className={styles.fieldLabel}>{t('Street No.')}: </span>
                    <span className={styles.fieldValue}>{address?.street_number}</span>
                </div>
                <div className={styles.field}>
                    <span className={styles.fieldLabel}>{t('City')}: </span>
                    <span className={styles.fieldValue}>{address?.city}</span>
                </div>
                <div className={styles.field}>
                    <span className={styles.fieldLabel}>{t('County')}: </span>
                    <span className={styles.fieldValue}>{address?.county}</span>
                </div>
                <div className={styles.field}>
                    <span className={styles.fieldLabel}>{t('Country')}: </span>
                    <span className={styles.fieldValue}>{getCountryById(address?.country_id)?.name}</span>
                </div>
                <div className={styles.field}>
                    <span className={styles.fieldLabel}>{t('Zipcode')}: </span>
                    <span className={styles.fieldValue}>{address?.zipcode}</span>
                </div>
            </div>
        </div>
    )
}

AddressBox.propTypes = {
    address: PropTypes.shape({
        id: PropTypes.number.isRequired,
        street: PropTypes.string.isRequired,
        street_number: PropTypes.string,
        city: PropTypes.string.isRequired,
        county: PropTypes.string,
        country_id: PropTypes.number.isRequired,
        zipcode: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
    isSelected: PropTypes.bool,
}

export default AddressBox