
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Image } from '../../components/ui';
import { ItemClientStatuses } from '../../library/constants/client-statuses.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { formatDate } from '../../library/utilities';
import ProgressBar from '../ui/progress-bar.jsx';
import styles from './project-item.module.scss';

const ProjectItem = ({ item }) => {
    const { t } = useTranslation();
    const { quotationToken } = useParams()
    const progress = useMemo(() => {
        if (item.client_status === ItemClientStatuses.CANCELLED) {
            return -1
        }
        return Math.trunc((100 / 5) * (item.client_status - 1))

    }, [item.client_status])
    return (
        <div className={`box--white ${styles.projectItem}`}>
            <div className={styles.header}>
                <div className={styles.productInfo}>
                    <h2>{item.product.name} <div className={styles.tooltip}> <img src="/icons/info.svg" />
                        <span className={styles.tooltiptext}>{item.specification.map(({ name, value }) => (
                            <React.Fragment key={name}>
                                <div className={styles.name}>{name}</div>
                                <div className={styles.value}>{value}</div>
                            </React.Fragment>
                        ))}</span>
                    </div></h2>
                    <Image src={`https://leoprinting.nl/files/photos/${item.product.main_photos?.[0]}`} width={300} height={250} className={styles.productImage} />
                </div>
                <div className={styles.progressBar}>
                    <ProgressBar value={progress} size="6rem" />
                </div>
                <div className={styles.itemDates}>
                    {item.client_expected_delivery_date && (
                        <div className="box--small box--blue-light">
                            {t('Requested Delivery Deadline')} <br />
                            <span className={styles.date}>{formatDate(item.client_expected_delivery_date)}</span>
                        </div>
                    )}
                    {item.client_status < ItemClientStatuses.UPLOAD_ARTWORK && item.deadline_artwork_customer && (
                        <div className="box--small box--blue-light">
                            {t('Artwork submission deadline')} <br />
                            <span className={styles.date}>{formatDate(item.deadline_artwork_customer)}</span>
                        </div>
                    )}
                    {item.client_status < ItemClientStatuses.IN_PRODUCTION && item.deadline_approval_proof_customer && (
                        <div className="box--small box--blue-light">
                            {t('Artwork approval deadling')} <br />
                            <span className={styles.date}>{formatDate(item.deadline_approval_proof_customer)}</span>
                        </div>
                    )}
                    {
                        item.client_status == ItemClientStatuses.IN_TRANSIT && <div className="box--small box--blue-light">
                            {t("Expected Transit")} <br />
                            {t("Date")}: <span className={styles.date}>{formatDate(item.client_expected_delivery_date)}</span>
                        </div>
                    }
                </div>
            </div>
            <br />
            {item.item_additional_quotations && item.item_additional_quotations.map((quotation) => {
                return (
                    quotation.status === 3 && <div className={`box--blue-light ${styles.task}`}>
                        <div className={styles.taskContent}>
                            <span>{t("Additional Quotation")} : #{quotation.id}</span>
                        </div>
                        <div className={styles.taskAction}>
                            <Link to={generateRouteUrl('AdditionalQuotationDetails', {
                                quotationToken: quotationToken,
                                itemId: item.id,
                                quotationId: quotation?.id
                            })} className="button--primary"> {t("Review")} </Link>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

ProjectItem.propTypes = {
    item: PropTypes.shape({
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            main_photos: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        client_expected_delivery_date: PropTypes.string,
        client_status: PropTypes.number.isRequired,
    }),
}

export default ProjectItem