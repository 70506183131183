export class AuthenticationError extends Error {
    constructor(onLogin) {
        super("You are required to login")
        this.onLogin = onLogin
    }
}

export class ActionError extends Error {
    constructor(message, data = null) {
        super(message)
        this.data = data
        this.name = this.constructor.name
    }
}

export class ValidationError extends Error {
    constructor(message, errors, merge = true) {
        super(message)
        if (merge) {
            this.errors = errors.reduce((acc, { path, message }) => {
                if (Array.isArray(path)) {
                    acc[path.join('.')] = [...(acc[path] || []), message]
                    //path.forEach(key => (acc[key] = [...(acc[key] || []), message]))
                }
                else {
                    acc[path] = [...(acc[path] || []), message]
                }
                return acc;
            }, {})
        }
        else {
            this.errors = errors
        }
        this.name = this.constructor.name
    }
}