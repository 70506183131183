import React, { forwardRef, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styles from './draw-signature.module.scss';

const DrawSignature = forwardRef((_, ref) => {
    const [canvasSize, setCanvasSize] = useState({ width: 1066, height: 400 });

    useEffect(() => {
        const updateCanvasSize = () => {
            const canvas = ref.current.getCanvas()
            const width = canvas.offsetWidth; // 90% of the viewport width
            const height = width / (8 / 3); // maintaining aspect ratio
            setCanvasSize({ width, height });
        };

        // Set initial size
        updateCanvasSize();

        // Listen for window resize
        window.addEventListener('resize', updateCanvasSize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateCanvasSize);
    }, []);

    return (
        <SignatureCanvas
            ref={ref}
            canvasProps={{
                width: canvasSize.width,
                height: canvasSize.height,
                className: `${styles.sigCanvas}`
            }}
        />
    )
})

export default DrawSignature