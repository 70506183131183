import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styles from './multifile-upload-with-comment.module.scss';

const getPreviewUrl = (file) => {
    if (/image\/[a-z+.-]+/.test(file.type)) {
        return file.preview
    }
    switch (file.type) {
        case "application/pdf":
            return "/icons/filetypes/pdf.svg"
        default:
            return "/icons/filetypes/file.svg"
    }
}
const MultiFileUploadWithComment = forwardRef(function MultiFileUploadWithComment(_, ref) {
    const [, setFiles] = useState([])
    const [reload, setReload] = useState()
    const { t } = useTranslation()

    useEffect(() => {
        if (!ref.current) {
            ref.current = []
        }
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => ref.current.forEach(file => URL.revokeObjectURL(file.file.preview));
    }, [reload]);
    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        maxSize: 10 * 1024 * 1024,
        maxFiles: 10,
        accept: {
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
            'image/tiff': ['.tiff', '.tif'],
            'image/svg+xml': ['.svg'],
            'image/vnd.adobe.photoshop': ['.psd'],
            'application/postscript': ['.eps'],
            'application/eps': ['.eps'],
            'application/x-eps': ['.eps'],
            'image/eps': ['.eps'],
            'image/x-eps': ['.eps'],
        },
        onDropRejected: rejections => {
            alert(rejections.map(({ errors }) => errors.map(({ message }) => message)).join("\n"))
        },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                addFile(file);
            });
        }
    })
    const addFile = (file) => {
        setFiles(files => {
            return [...files, Object.assign(file, {
                preview: URL.createObjectURL(file)
            })]
        })
        ref.current.push({ file, comment: null })
    }
    const updateComment = (index, comment) => {
        // ref.commentData.push(cmnt)
        if (!ref.current?.[index]) {
            ref.current[index] = {}
        }
        ref.current[index].comment = comment
    }
    const handleDeleteFile = (index) => {
        ref.current.splice(index, 1)
        setReload(!reload)
    }
    return (
        <>
            <div className={styles.files}>
                {ref.current.length > 0 && (
                    ref.current.map(({ file, comment }, index) => (
                        <div className={styles.file} key={file.name}>
                            <img alt="prevoiew"
                                src={getPreviewUrl(file)}
                            // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                            <div style={{ width: '200px', overflow: 'hidden' }}>
                                <p className="label">{file.name}</p>
                            </div>
                            <div style={{ 'width': '25%' }}>
                                <input className={styles.inputFields} onBlur={(e) => {
                                    updateComment(index, e.target.value)
                                }} defaultValue={comment} name={index} label="Add a comment" placeholder="type your message here.." />
                            </div>
                            <div style={{ 'width': '20%' }}>
                                <button className={`button--primary ${styles.uploadButton ?? ''}`} onClick={() => { handleDeleteFile(index) }}  >Delete</button>
                            </div>
                        </div>
                    ))
                )}
            </div>
            {/* <div className={styles.wrapper}>
                <div {...getRootProps({ className: styles.dropArea })}>
                    <div className={styles.dropIcon}>
                        <img src="/icons/upload-drop.svg" />
                    </div>
                    <div className={styles.dropText}>
                        Drag and drop your signature
                    </div>
                    <p>------------ OR ------------</p>
                    <input className="input-zone" {...getInputProps()} />
                    <div className={`button--primary ${styles.uploadButton ?? ''}`}>Browse your file</div>

                    {!!ref.current && (<div key={ref.current.name}>
                        <img alt=""
                            src={ref.current.preview}
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(ref.current.preview) }}
                        />
                    </div>)}
                </div>
            </div> */}
            <div className={styles.wrapper} >
                <input type="file" {...getInputProps()} style={{ display: "none" }} />
                <div {...getRootProps({ className: styles.dropArea })}>
                    <div className={styles.dropIcon}>
                        <img src="/icons/upload-drop.svg" />
                    </div>
                    <div className={styles.dropText}>
                        {t('Drag and drop your signature')}
                    </div>
                    <p>------------ OR ------------</p>
                    <div className={`button--primary ${styles.uploadButton ?? ''}`}>{t('Browse your file')}</div>
                    {!!ref.current && (<div key={ref.current.name}>
                        <img alt=""
                            src={ref.current.preview}
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(ref.current.preview) }}
                        />
                    </div>)}
                </div>
            </div>
        </>
    )
})
MultiFileUploadWithComment.propTypes = {
    inputName: PropTypes.string,
}
export default MultiFileUploadWithComment;
